import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { TrackerStateModel } from './tracker.model'
import { TrackerService } from '../tracker.service'
import { GetTrackerLocations, GetTrackersData, ResetTrackers } from './tracker.actions'
import { groupBy, max, min } from 'lodash'

@State<TrackerStateModel>({
  name: 'tracker'
})
@Injectable()
export class TrackerState {
  constructor(private trackerService: TrackerService) {}

  @Selector()
  static locations(state: TrackerStateModel) {
    return state.locations
  }

  @Selector()
  static trackers(state: TrackerStateModel) {
    if (!state.trackers) {
      return []
    }
    const groupedObjectives = groupBy(
      state.trackers
        ?.map(
          (el) =>
            el.items?.map((item) => ({
              ...item,
              period: el.timePeriod?.period,
              timePeriod: el.timePeriod
            })) ?? []
        )
        .flat(),
      (el) => el.label
    )

    return Object.keys(groupedObjectives)?.map((key) => {
      const periodValues = groupBy(groupedObjectives[key], (el) => el.period)
      const values = Object.values(periodValues)
        .map((el) => el[0].value)
        .slice(0, -1)
      const maxValue = max(values)
      const minValue = min(values)
      return {
        objective: key,
        ...periodValues,
        maxValue,
        minValue
      }
    })
  }

  @Action(GetTrackerLocations)
  getTrackerLocations(ctx: StateContext<TrackerStateModel>) {
    return this.trackerService.getTrackerLocations().pipe(
      tap((res) => {
        ctx.patchState({
          locations: res?.items ?? []
        })
      })
    )
  }

  @Action(ResetTrackers)
  resetTrackers(ctx: StateContext<TrackerStateModel>) {
    ctx.patchState({
      trackers: []
    })
  }

  @Action(GetTrackersData)
  getTrackersData(ctx: StateContext<TrackerStateModel>, { payload }: GetTrackersData) {
    return this.trackerService.getTrackerData(payload).pipe(
      tap((res) => {
        ctx.patchState({
          trackers: res?.list ?? []
        })
      })
    )
  }
}
