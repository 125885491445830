import { IGroupShopsResponse } from '@lla-platform/core/core-data-access'

export class GetAllShops {
  static readonly type = '[shops] Get All Shops'
}

export class SetGroupShops {
  static readonly type = '[shops] Set Group Shops'
  constructor(public payload: IGroupShopsResponse) {}
}
