import { ISelectItem } from '@lla-platform/core/core-ui'

export enum LostTireCallType {
  NotSet = 'NotSet',
  InPerson = 'InPerson',
  PhoneCall = 'PhoneCall'
}

export const LostTireCallTypeItems: ISelectItem[] = [
  {
    label: 'Not Set',
    value: LostTireCallType.NotSet
  },
  {
    label: 'In Person',
    value: LostTireCallType.InPerson
  },
  {
    label: 'Phone Call',
    value: LostTireCallType.PhoneCall
  }
]
