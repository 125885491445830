import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router'
import { WeekToDateQueryParam } from '../../layout/main-layout/main-layout/main-layout.constant'

export class CustomReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.queryParams && future.queryParams['filters'] === WeekToDateQueryParam.filters) {
      return false
    }

    return super.shouldReuseRoute(future, curr)
  }
}
