import { ApplicationRoutes } from '../../app-routes-names'

export const AdminRoutes = {
  shopTires: 'location-tires',
  userList: 'user-list',
  userDetails: 'user-details/:userId',
  addUser: 'add-user',
  promotionsList: 'promotions-list',
  editPromotion: 'promotions-details/:promotionId',
  addPromotion: 'promotions-details',
  emailPromotions: 'email-promotions',
  setMargins: 'set-margins',
  surveyList: 'survey-list'
}

const { admin } = ApplicationRoutes

export const AdminPaths = {
  shopTires: `/${admin}/${AdminRoutes.shopTires}`,
  userList: `/${admin}/${AdminRoutes.userList}`,
  userDetails: (userId: string) => `/${admin}/${AdminRoutes.userDetails.replace(':userId', userId)}`,
  addUser: `/${admin}/${AdminRoutes.addUser}`,
  promotionsList: `/${admin}/${AdminRoutes.promotionsList}`,
  editPromotion: (promotionId: string) =>
    `/${admin}/${AdminRoutes.editPromotion.replace(':promotionId', promotionId)}`,
  addPromotion: `/${admin}/${AdminRoutes.addPromotion}`,
  emailPromotions: `/${admin}/${AdminRoutes.emailPromotions}`,
  setMargins: `/${admin}/${AdminRoutes.setMargins}`,
  surveyList: `/${admin}/${AdminRoutes.surveyList}`
}
