export enum CommunicationStatus {
  Answered = 'Answered',
  NotAnswered = 'NotAnswered',
  VoiceMessage = 'VoiceMessage'
}

export const CommunicationStatusToLabel: { [key: string]: string } = {
  Answered: 'Answered',
  NotAnswered: 'Not answered',
  VoiceMessage: 'Voice message'
}
