import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { REVIEWS_API_URL } from './reviews-api-routes'
import { Observable } from 'rxjs'
import { IReviewsResponse } from '../interfaces/reviews.interface'
import { environment } from '@lla-platform/core/core-util'
import { IReplySugestionRequest, IReplySugestionResponse } from '../interfaces/reply-sugestion.interface'
import { IReplyReviewRequest } from '../interfaces/reply-review.interface'

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  constructor(private http: HttpService) {}

  checkToken(authorizationCode: string) {
    return this.http.post(REVIEWS_API_URL.checkToekn, {
      authorizationCode,
      redirectUri: this.generateRedirectUrl()
    })
  }

  getReviews(): Observable<IReviewsResponse> {
    return this.http.get(`${REVIEWS_API_URL.getReviews}?redirectUrl=${this.generateRedirectUrl()}`)
  }

  replySugestion(payload: Partial<IReplySugestionRequest>): Observable<IReplySugestionResponse> {
    return this.http.post(REVIEWS_API_URL.replySugestion, payload)
  }

  replyReview(payload: IReplyReviewRequest) {
    return this.http.post(REVIEWS_API_URL.replyReview, {
      ...payload,
      redirectUrl: this.generateRedirectUrl()
    })
  }

  private generateRedirectUrl() {
    return `${environment.websiteUrl}/google`
  }
}
