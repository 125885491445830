import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ShopsStateModel } from './shops.model'
import { ShopsService } from '../shops.service'
import { GetAllShops, SetGroupShops } from './shops.actions'
import { sortBy, uniq } from 'lodash'

@State<ShopsStateModel>({
  name: 'shops'
})
@Injectable()
export class ShopsState {
  constructor(private shopsService: ShopsService) {}

  @Selector()
  static allShops(state: ShopsStateModel) {
    return state.allShops
  }

  @Selector()
  static groupShops(state: ShopsStateModel) {
    return state.groupShops
  }

  @Selector()
  static groups(state: ShopsStateModel) {
    return uniq((state.groupShops ?? []).map((el) => el.groups ?? []).flat())
  }

  @Action(GetAllShops)
  getCallsReport(ctx: StateContext<ShopsStateModel>) {
    return this.shopsService.getAllShops().pipe(
      tap((res) => {
        ctx.patchState({
          allShops: res
        })
      })
    )
  }

  @Action(SetGroupShops)
  setGroupShops(ctx: StateContext<ShopsStateModel>, { payload }: SetGroupShops) {
    ctx.patchState({
      groupShops: sortBy(payload?.locations ?? [], (x) => x.label)
    })
  }
}
