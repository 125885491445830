import { Injectable } from '@angular/core'
import { HttpService, IExportFileResponse } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  IWeeklyCarCountsRequest,
  IWeeklyCarCountsResponse
} from '../interfaces/weekly-car-counts.interface'
import { CAR_COUNTS_API_URL } from './car-counts-api-routes'

@Injectable({
  providedIn: 'root'
})
export class CarCountsService {
  constructor(private http: HttpService) {}

  getWeeklyCarCounts(payload: IWeeklyCarCountsRequest): Observable<IWeeklyCarCountsResponse> {
    return this.http.post(CAR_COUNTS_API_URL.weeklyCarCounts, payload)
  }

  exportWeeklyCarCounts(payload: IWeeklyCarCountsRequest): Observable<IExportFileResponse> {
    return this.http.post(CAR_COUNTS_API_URL.exportWeeklyCarCounts, payload)
  }
}
