import { ApplicationRoutes } from '../../../app-routes-names'

export const CustomersRoutes = {
  list: 'list',
  communicationType: 'communication-type',
  detail: 'detail/:customerId/:shopId',
  detailWithInvoice: 'detail/:customerId/:shopId/invoice/:invoiceId',
  detailWithCall: 'detail/:customerId/:shopId/call/:callId'
}

const { contacts } = ApplicationRoutes

export const CustomersPaths = {
  list: `/${contacts}/${CustomersRoutes.list}`,
  communicationType: `/${contacts}/${CustomersRoutes.communicationType}`,
  detail: (customerId: string, shopId: string) =>
    `/${contacts}/${CustomersRoutes.detail
      .replace(':customerId', customerId)
      .replace(':shopId', shopId)}`,
  detailWithInvoice: (customerId: string, shopId: string, invoiceId: string) =>
    `/${contacts}/${CustomersRoutes.detailWithInvoice
      .replace(':customerId', customerId)
      .replace(':shopId', shopId)
      .replace(':invoiceId', invoiceId)}`,
  detailWithCall: (customerId: string, shopId: string, callId: string) =>
    `/${contacts}/${CustomersRoutes.detailWithCall
      .replace(':customerId', customerId)
      .replace(':shopId', shopId)
      .replace(':callId', callId)}`
}
