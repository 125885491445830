<div class="pt-4 px-5 md:pt-6 pb-6" *ngIf="currentUser$ | async as currentUser">
  <div class="flex flex-col">
    <span class="text-txt text-2.5xl leading-3xl mb-0.5">My Profile</span>
    <span class="text-txt-secondary text-xl leading-2xl">
      Hello {{ currentUser.firstName }} {{ currentUser.lastName }}
    </span>
  </div>

  <div class="mt-5.5">
    <lla-tabs
      [sections]="sections"
      (selectedSectionChanged)="selectedRoute = $event; router.navigateByUrl(selectedRoute)"
      [selectedSection]="selectedRoute"
      [smallScreensSpaceBetween]="true"
    ></lla-tabs>
    <div class="mt-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
