import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  IAdminPromotionItem,
  IAdminPromotionsResponse,
  IPromotionItem,
  IPromotionsResponse
} from '../interfaces/promotions.interface'
import { PROMOTIOMS_API_URL } from './promotions-api-routes'
import { IPromotionChannelResponse } from '../interfaces/promotion-channel.interface'
import { IPromotionEmailRequest, IPromotionUsersResponse } from '../interfaces/promotion-users.interface'

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {
  constructor(private http: HttpService) {}

  getPromotion(promotionId: string): Observable<IPromotionItem> {
    return this.http.get(PROMOTIOMS_API_URL.baseAdmin(promotionId))
  }

  addPromotion(payload: IPromotionItem): Observable<IAdminPromotionItem> {
    return this.http.post(PROMOTIOMS_API_URL.baseAdmin(), payload)
  }

  updatePromotion(promotionId: string, payload: IPromotionItem) {
    return this.http.put(PROMOTIOMS_API_URL.baseAdmin(promotionId), payload)
  }

  deletePromotion(promotionId: string) {
    return this.http.delete(PROMOTIOMS_API_URL.baseAdmin(promotionId))
  }

  getAllPromotions(): Observable<IPromotionsResponse> {
    return this.http.get(PROMOTIOMS_API_URL.getAllPromotions)
  }

  adminGetAllPromotions(): Observable<IAdminPromotionsResponse> {
    return this.http.get(PROMOTIOMS_API_URL.baseAdmin())
  }

  getPromotionsChannels(): Observable<IPromotionChannelResponse> {
    return this.http.get(PROMOTIOMS_API_URL.getPromotionsChannels)
  }

  uploadPromotionsAttachments(promotionId: string, files?: File[]) {
    const formData = new FormData()

    if (files && files.length > 0) {
      files.forEach((el) => formData.append('files', el, el.name))
    }

    return this.http.post(PROMOTIOMS_API_URL.promotionsAttachments(promotionId), formData)
  }

  deletePromotionAttachment(promotionId: string, fileId: string) {
    return this.http.delete(PROMOTIOMS_API_URL.promotionsAttachments(promotionId, fileId))
  }

  getPromotionUsers(): Observable<IPromotionUsersResponse> {
    return this.http.get(PROMOTIOMS_API_URL.getPromotionUsers)
  }

  sendPromotionEmail(payload: IPromotionEmailRequest) {
    return this.http.post(PROMOTIOMS_API_URL.sendPromotionEmail, payload)
  }
}
