import { ISelectItem } from '@lla-platform/core/core-ui'

export enum TireType {
  NotSet = 'NotSet',
  Bfs = 'Bfs',
  Advanta = 'Advanta'
}

export const TireTypeItems: ISelectItem[] = [
  {
    label: 'Advanta',
    value: TireType.Advanta
  },
  {
    label: 'Bfs',
    value: TireType.Bfs
  }
]
