import { IInputErrorMessages } from './input.interface'

export const globalErrorMessages: IInputErrorMessages[] = [
  {
    type: 'required',
    message: 'This field is required'
  },
  {
    type: 'email',
    message: 'Please enter a valid email'
  }
]
