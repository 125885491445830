<lla-input
  class="w-full"
  type="google-places"
  [controlName]="controlName"
  [label]="label"
  (googlePlaceAddressChanged)="handleAddressChange($event)"
  [loading]="loading"
  size="small"
></lla-input>

<img class="mt-1" src="assets/images/Powered_by_google.svg" />
