import { Injectable } from '@angular/core'
import { HttpService, IExportFileResponse } from '@lla-platform/core/core-data-access'
import { Tracker_API_URL } from './tracker-api-routes'
import { Observable } from 'rxjs'
import { ITrackerLocationResponse } from '../interfaces/tracker-locations.interface'
import { ITrackerRequest, ITrackerResponse } from '../interfaces/trackers.interface'

@Injectable({
  providedIn: 'root'
})
export class TrackerService {
  constructor(private http: HttpService) {}

  getTrackerLocations(): Observable<ITrackerLocationResponse> {
    return this.http.get(Tracker_API_URL.base)
  }

  getTrackerData(payload: ITrackerRequest): Observable<ITrackerResponse> {
    return this.http.post(Tracker_API_URL.base, payload)
  }

  exportTrackerData(payload: ITrackerRequest): Observable<IExportFileResponse> {
    return this.http.post(Tracker_API_URL.exportTrackersData, payload)
  }
}
