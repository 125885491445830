import { ApplicationRoutes } from '../../../app-routes-names'

export const ReceivableRoutes = {
  shopsSummary: 'locations',
  receivablesList: 'receivables-list',
  arInvoicesSummary: 'ar',
  accountsSummary: 'accounts',
  arPaymentsSummary: 'ar-payments'
}

const { receivable } = ApplicationRoutes

export const ReceivablePaths = {
  shopsSummary: `/${receivable}/${ReceivableRoutes.shopsSummary}`,
  receivablesList: `/${receivable}/${ReceivableRoutes.receivablesList}`,
  arInvoicesSummary: `/${receivable}/${ReceivableRoutes.arInvoicesSummary}`,
  accountsSummary: `/${receivable}/${ReceivableRoutes.accountsSummary}`,
  arPaymentsSummary: `/${receivable}/${ReceivableRoutes.arPaymentsSummary}`
}
