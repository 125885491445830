import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { ApplicationRoutes } from '../../app-routes-names'
import { ReviewsService } from '@lla-platform/reviews/reviews-data-access'

@Injectable({
  providedIn: 'root'
})
export class GoogleGuard {
  constructor(private router: Router, private reviewsService: ReviewsService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const code = route.queryParams['code']
    if (code) {
      try {
        await firstValueFrom(this.reviewsService.checkToken(code))
        this.router.navigateByUrl(ApplicationRoutes.reviews)
      } catch (error) {
        this.router.navigateByUrl(ApplicationRoutes.dashboard)
      }
    } else {
      this.router.navigateByUrl(ApplicationRoutes.dashboard)
    }

    return true
  }
}
