export enum SatisfactionType {
  NotSet = 'NotSet',
  ClientSatisfied = 'ClientSatisfied',
  ClientHasComplain = 'ClientHasComplain'
}

export const SatisfactionTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not set',
  ClientSatisfied: 'Client is satisfied',
  ClientHasComplain: 'Client has a complain'
}
