import { environment } from '@lla-platform/core/core-util'

export const CUSTOMERS_API_URL = {
  // Customers
  customersTypes: `${environment.apiUrl}/invoices/contactstype`,
  exportCustomersTypes: `${environment.apiUrl}/invoices/contactstype/export`,
  customersList: `${environment.apiUrl}/contacts/filters`,
  exportCustomersList: `${environment.apiUrl}/contacts/filters/export`,
  getcustomer: (customerId: string, shopId: string) =>
    `${environment.apiUrl}/contacts/${customerId}/${shopId}`,
  updateCustomer: (customerId: string) => `${environment.apiUrl}/contacts/${customerId}`,
  geoLocation: `${environment.apiUrl}/contacts/geo-location`,
  exportGeoLocation: `${environment.apiUrl}/contacts/geo-location/export`,

  // Customers Tags
  customerGetTags: (customerId: string, shopId: string) =>
    `${environment.apiUrl}/contacts/${customerId}/${shopId}/tag`,
  customerTagBase: (customerId: string) => `${environment.apiUrl}/contacts/${customerId}/tag`,

  // Customers Invoices
  getCustomerInvoices: (customerId: string, shopId: string) =>
    `${environment.apiUrl}/invoices/contact/${customerId}/${shopId}`,

  // Communication
  getCommunication: (customerId: string, shopId: string, id: string) =>
    `${environment.apiUrl}/communications/contact/${customerId}/${shopId}/${id}`,
  getCommunicationHistory: (customerId: string, shopId: string) =>
    `${environment.apiUrl}/communications/contact/${customerId}/${shopId}/history`,
  addCommunication: (customerId: string) => `${environment.apiUrl}/communications/contact/${customerId}`,
  updateCommunication: (customerId: string) =>
    `${environment.apiUrl}/communications/contact/${customerId}`,
  communicationsLists: `${environment.apiUrl}/communications/filters`,
  exportCommunicationsList: `${environment.apiUrl}/communications/filters/export`,
  getAgents: `${environment.apiUrl}/communications/agents`,

  // Communication type
  getCommunicationType: `${environment.apiUrl}/contacts/communication-type`,
  exportCommunicationType: `${environment.apiUrl}/contacts/communication-type/export`,

  // Customers
  deferredTypes: `${environment.apiUrl}/invoices/deferredtype`,
  exportDeferredTypes: `${environment.apiUrl}/invoices/deferredtype/export`
}
