import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { PageStateModel } from './page.model'
import { PageService } from '../page.service'
import { GetWhoToCallPage, UpdateWhoToCallPage } from './page.actions'

@State<PageStateModel>({
  name: 'page'
})
@Injectable()
export class PageState {
  whoToCallType = 'WhoToCall'

  constructor(private pageService: PageService) {}

  @Selector()
  static pageData(state: PageStateModel) {
    return state.pageData
  }

  @Action(GetWhoToCallPage)
  getWhoToCallPage(ctx: StateContext<PageStateModel>) {
    return this.pageService.getPageData(this.whoToCallType).pipe(
      tap((res) => {
        ctx.patchState({
          pageData: res
        })
      })
    )
  }

  @Action(UpdateWhoToCallPage)
  updateWhoToCallPage(ctx: StateContext<PageStateModel>, { payload }: UpdateWhoToCallPage) {
    return this.pageService.updatePageData({
      ...payload,
      type: this.whoToCallType,
      title: 'Who to Call'
    })
  }
}
