import { IAddBookmarkRequest, IBookmarkItem } from '../../interfaces/bookmarks.interface'

export class SetBookmarks {
  static readonly type = '[bookmarks] Set Bookmarks'
  constructor(public payload: IBookmarkItem[]) {}
}

export class AddNewBookmark {
  static readonly type = '[bookmarks] Add Bookmark'
  constructor(public payload: IAddBookmarkRequest) {}
}

export class DeleteBookmark {
  static readonly type = '[bookmarks] Delete Bookmark'
  constructor(public id: string) {}
}
