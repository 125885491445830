import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ADMIN_API_URL } from './admin-api-routes'
import { IAdminShopTiersResponse, IAdminShopTiersUpdate } from '../interfaces/admin-shop-tires.interface'
import { IBaseUserResponse, Permission } from '@lla-platform/users/users-data-access'
import {
  IAdminUpdatePermissionsRequest,
  IAdminUserPermissionsResponse
} from '../interfaces/admin-user-permissions.interface'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpService) {}

  getShopTires(): Observable<IAdminShopTiersResponse> {
    return this.http.get(ADMIN_API_URL.targets)
  }

  updateShopTires(payload: IAdminShopTiersUpdate) {
    return this.http.post(ADMIN_API_URL.targets, payload)
  }

  getAllUsers(): Observable<IBaseUserResponse> {
    return this.http.get(ADMIN_API_URL.users)
  }

  getUserPermissions(userId: string): Observable<IAdminUserPermissionsResponse> {
    return this.http.get(ADMIN_API_URL.userPermissions(userId))
  }

  updateUserPermissions(userId: string, payload: IAdminUpdatePermissionsRequest) {
    return this.http.post(ADMIN_API_URL.userPermissions(userId), payload)
  }

  getAllPermissions(): Observable<Permission[]> {
    return this.http.get(ADMIN_API_URL.userPermissions())
  }
}
