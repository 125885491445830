import { Injectable } from '@angular/core'
import { HttpService, IExportFileResponse } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ISurveyRequest, ISurveyResponse } from '../interfaces/survey.interface'
import { SURVEY_API_URL } from './survey-api-routes'
import { ISurveySummaryResponse } from '../interfaces/admin-survey.interface'

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(private http: HttpService) {}

  getSurvey(surveyId: string, locationId?: string): Observable<ISurveyResponse> {
    return this.http.get(
      SURVEY_API_URL.surveyBase(surveyId),
      locationId
        ? {
            params: {
              locationId
            }
          }
        : undefined
    )
  }

  sendSurveyResponse(surveyId: string, payload: ISurveyRequest) {
    return this.http.post(SURVEY_API_URL.surveyBase(surveyId), payload)
  }

  adminGetSurveySummary(): Observable<ISurveySummaryResponse> {
    return this.http.get(SURVEY_API_URL.adminGetSurveySummary)
  }

  adminExportSurvey(surveyId: string): Observable<IExportFileResponse> {
    return this.http.get(SURVEY_API_URL.adminExportSurvey(surveyId))
  }
}
