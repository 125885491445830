import {
  ICommunicationRequest,
  IUpdateCommunicationRequest
} from '../../interfaces/communications.interface'
import { ITagAddRequest, ITagDeleteRequest } from '../../interfaces/customers-tag.interface'
import { ICustomerRequest, ICustomerUpdateRequest } from '../../interfaces/customers.interface'
import { ICustomersListInfo } from '../../interfaces/customers-list.interface'
import { ICommunicationsListInfo } from '../../interfaces/communications-list.interface'
import { ICustomerGeoLocationRequest } from '../../interfaces/customers-geolocation.interface'
import { IDeferredTypeRequest } from '../../interfaces/deferred.interface'
import { ICommunicationTypeRequest } from '../../interfaces/communication-type.interface'

export class GetCustomersTypes {
  static readonly type = '[customers] Get Customers Types'
  constructor(public payload: ICustomerRequest) {}
}

export class GetCustomersList {
  static readonly type = '[customers] Get Customers List'
  constructor(public payload: ICustomersListInfo) {}
}

export class GetCustomer {
  static readonly type = '[customers] Get Customer'
  constructor(public customerId: string, public shopId: string) {}
}

export class UpdateCustomer {
  static readonly type = '[customers] Update Customer'
  constructor(public customerId: string, public payload: ICustomerUpdateRequest) {}
}

export class GetCustomersInRadius {
  static readonly type = '[customers] Get Customers In Radius'
  constructor(public payload: ICustomerGeoLocationRequest) {}
}

export class GetCustomerTags {
  static readonly type = '[customers] Get Customer Tags'
  constructor(public customerId: string, public shopId: string) {}
}

export class AddCustomerTag {
  static readonly type = '[customers] Add Customer Tag'
  constructor(public customerId: string, public payload: ITagAddRequest) {}
}

export class DeleteCustomerTag {
  static readonly type = '[customers] Delete Customer Tag'
  constructor(public customerId: string, public payload: ITagDeleteRequest) {}
}

export class GetCustomerInvoices {
  static readonly type = '[customers] Get Customer Invoices'
  constructor(public customerId: string, public shopId: string) {}
}

export class GetCommunication {
  static readonly type = '[customers] Get Communication'
  constructor(public customerId: string, public shopId: string, public id: string) {}
}

export class GetCommunicationHistory {
  static readonly type = '[customers] Get Communication History'
  constructor(public customerId: string, public shopId: string) {}
}

export class AddCommunication {
  static readonly type = '[customers] Add Communication'
  constructor(public customerId: string, public payload: ICommunicationRequest) {}
}

export class UpdateCommunication {
  static readonly type = '[customers] Update Communication'
  constructor(public customerId: string, public payload: IUpdateCommunicationRequest) {}
}

export class GetCommunicationsList {
  static readonly type = '[customers] Get Communications List'
  constructor(public payload: ICommunicationsListInfo) {}
}

export class GetAgents {
  static readonly type = '[customers] Get Agents'
}

export class GetCommunicationType {
  static readonly type = '[customers] Get Communication Type'
  constructor(public payload: ICommunicationTypeRequest) {}
}

export class GetDeferredTypes {
  static readonly type = '[customers] Get Deferred Types'
  constructor(public payload: IDeferredTypeRequest) {}
}
