import { IAdvancedTableInfo } from '@lla-platform/core/core-data-access'

export class GetInventoriesSummary {
  static readonly type = '[inventories] Get Inventories Summary'
}

export class GetInventoriesList {
  static readonly type = '[inventories] Get Inventories List'
  constructor(public info: IAdvancedTableInfo) {}
}

export class UpdateInventoryTire {
  static readonly type = '[inventories] Update Inventory Tire'
  constructor(public inventoryId: string, public isTire: boolean) {}
}
