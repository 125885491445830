import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { TiresStateModel } from './tires.model'
import { TiresService } from '../tires.service'
import { ITiresExtendedResponse } from '../../interfaces/tires.interface'
import {
  AddLostTire,
  GetLostTireInfo,
  GetLostTiresList,
  GetTiresList,
  GetTiresSummary,
  UpdateTireItemIsTire
} from './tires.actions'
import { ITiresListResponse } from '../../interfaces/tires-list.interface'
import { TireType } from '../../enums/tire-type.enum'

@State<TiresStateModel>({
  name: 'tires'
})
@Injectable()
export class TiresState {
  constructor(private tiresService: TiresService, private tableFilterService: TableFilterService) {}

  @Selector()
  static tires(state: TiresStateModel): ITiresExtendedResponse | undefined {
    if (!state.tires) {
      return
    }
    return {
      timePeriod: state.tires.timePeriod,
      locationTireSummary: state.tires.locationTireSummary.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,
        advantaTiresEntries: el.advantaTires?.entries ?? 0,
        advantaTiresQuantity: el.advantaTires?.quantity ?? 0,
        advantaTiresFormattedQuantity: el.advantaTires?.formattedQuantity ?? '',
        advantaTiresPercentage:
          !el.tires?.quantity || el.tires?.quantity === 0
            ? 0
            : ((el.advantaTires?.quantity ?? 0) / el.tires.quantity) * 100,
        bfsTiresEntries: el.bfsTires?.entries ?? 0,
        bfsTiresQuantity: el.bfsTires?.quantity ?? 0,
        bfsTiresFormattedQuantity: el.bfsTires?.formattedQuantity ?? '',
        bfsTiresPercentage:
          !el.tires?.quantity || el.tires?.quantity === 0
            ? 0
            : ((el.bfsTires?.quantity ?? 0) / el.tires.quantity) * 100,
        tiresEntries: el.tires?.entries ?? 0,
        tiresQuantity: el.tires?.quantity ?? 0,
        tiresFormattedQuantity: el.tires?.formattedQuantity ?? '',
        tiresAndAccessoriesEntries: el.tiresAndAccessories?.entries ?? 0,
        tiresAndAccessoriesQuantity: el.tiresAndAccessories?.quantity ?? 0,
        tiresAndAccessoriesFormattedQuantity: el.tiresAndAccessories?.formattedQuantity ?? '',
        totalsEntries: el.totals?.entries ?? 0,
        totalsQuantity: el.totals?.quantity ?? 0,
        totalsFormattedQuantity: el.totals?.formattedQuantity ?? ''
      })),
      grandTotal: {
        advantaTiresEntries: state.tires.grandTotalAdvantaTires?.entries ?? 0,
        advantaTiresQuantity: state.tires.grandTotalAdvantaTires?.quantity ?? 0,
        advantaTiresFormattedQuantity: state.tires.grandTotalAdvantaTires?.formattedQuantity ?? '',
        advantaTiresPercentage:
          !state.tires.grandTotalTires?.quantity || state.tires.grandTotalTires?.quantity === 0
            ? 0
            : ((state.tires.grandTotalAdvantaTires?.quantity ?? 0) /
                state.tires.grandTotalTires?.quantity) *
              100,
        bfsTiresEntries: state.tires.grandTotalBfsTires?.entries ?? 0,
        bfsTiresQuantity: state.tires.grandTotalBfsTires?.quantity ?? 0,
        bfsTiresFormattedQuantity: state.tires.grandTotalBfsTires?.formattedQuantity ?? '',
        bfsTiresPercentage:
          !state.tires.grandTotalTires?.quantity || state.tires.grandTotalTires?.quantity === 0
            ? 0
            : ((state.tires.grandTotalBfsTires?.quantity ?? 0) / state.tires.grandTotalTires?.quantity) *
              100,
        tiresEntries: state.tires.grandTotalTires?.entries ?? 0,
        tiresQuantity: state.tires.grandTotalTires?.quantity ?? 0,
        tiresFormattedQuantity: state.tires.grandTotalTires?.formattedQuantity ?? '',
        tiresAndAccessoriesEntries: state.tires.grandTotalTiresAndAccessories?.entries ?? 0,
        tiresAndAccessoriesQuantity: state.tires.grandTotalTiresAndAccessories?.quantity ?? 0,
        tiresAndAccessoriesFormattedQuantity:
          state.tires.grandTotalTiresAndAccessories?.formattedQuantity ?? '',
        totalsEntries: state.tires.globalTotals?.entries ?? 0,
        totalsQuantity: state.tires.globalTotals?.quantity ?? 0,
        totalsFormattedQuantity: state.tires.globalTotals?.formattedQuantity ?? ''
      }
    }
  }

  @Selector()
  static listResponse(state: TiresStateModel): ITiresListResponse | undefined {
    if (!state.listResponse) {
      return
    }
    return {
      count: state.listResponse.count,
      totals: state.listResponse.totals,
      tires: state.listResponse.tires?.map((el) => ({
        ...el,
        tireType: el.tireType === TireType.NotSet ? undefined : el.tireType
      }))
    }
  }

  @Selector()
  static lostTireslistResponse(state: TiresStateModel) {
    return state.lostTireslistResponse
  }

  @Selector()
  static lostTireInfo(state: TiresStateModel) {
    return state.lostTireInfo
  }

  @Action(GetTiresSummary, { cancelUncompleted: true })
  getTiresSummary(ctx: StateContext<TiresStateModel>, { payload }: GetTiresSummary) {
    return this.tiresService.getTiresSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          tires: res
        })
      })
    )
  }

  @Action(GetTiresList)
  getTiresList(ctx: StateContext<TiresStateModel>, { info }: GetTiresList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.tiresService.getTiresList(this.tableFilterService.createRequestPayload(info)).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }

  @Action(UpdateTireItemIsTire)
  updateTireItemIsTire(
    ctx: StateContext<TiresStateModel>,
    { itemId, isTire, tireType }: UpdateTireItemIsTire
  ) {
    return this.tiresService.updateTire(itemId, isTire, tireType)
  }

  @Action(GetLostTireInfo)
  getLostTireInfo(ctx: StateContext<TiresStateModel>) {
    ctx.patchState({
      lostTireInfo: undefined
    })
    return this.tiresService.getLostTireInfo().pipe(
      tap((res) => {
        ctx.patchState({
          lostTireInfo: res
        })
      })
    )
  }

  @Action(AddLostTire)
  addLostTire(ctx: StateContext<TiresStateModel>, { payload }: AddLostTire) {
    return this.tiresService.addLostTire(payload)
  }

  @Action(GetLostTiresList)
  getLostTiresList(ctx: StateContext<TiresStateModel>, { info }: GetLostTiresList) {
    ctx.patchState({
      lostTireslistResponse: undefined
    })
    return this.tiresService.getLostTiresList(this.tableFilterService.createRequestPayload(info)).pipe(
      tap((res) => {
        ctx.patchState({
          lostTireslistResponse: res
        })
      })
    )
  }
}
