<div
  class="w-[320px] h-full p-3 pb-6 hidden lg:flex flex-col border-r border-gray-100 overflow-auto beautyScroll"
>
  <!-- Main Items -->
  <div>
    <ng-container *ngFor="let item of mains">
      <ng-container *ngTemplateOutlet="expandableMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>

  <!-- Service Items -->
  <div>
    <ng-container *ngFor="let item of services">
      <ng-container *ngTemplateOutlet="expandableMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>

  <!-- Bookmarks Items -->
  <div *ngIf="bookmarks && bookmarks.length > 0" class="group-header">
    <span>Bookmarks</span>
    <svg-icon key="dots-horizontal" color="#C3C3C3"></svg-icon>
  </div>
  <div>
    <ng-container *ngFor="let item of bookmarks">
      <ng-container *ngTemplateOutlet="expandableMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>
</div>

<!-- Expandable Menu Item Template -->
<ng-template #expandableMenuItem let-item="item">
  <div *ngIf="!item.hidden" class="border-b last:border-b-0 border-gray-100">
    <div (click)="changeCollapsableMenuStatus(item)">
      <ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
    </div>
    <ng-container *ngIf="isSubMenuVisible(item)">
      <ng-container *ngFor="let subMenu of item.subMenus">
        <ng-container
          *ngTemplateOutlet="menuItem; context: { item: subMenu, isSubMenuItem: true }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Item Template -->
<ng-template #menuItem let-item="item" let-isSubMenuItem="isSubMenuItem">
  <a
    *ngIf="!item.hidden"
    class="menu-item group"
    [routerLink]="item.path"
    [queryParams]="item.queryParams"
    [routerLinkActive]="item.type === 'bookmark' ? '' : 'active-menu-item'"
    [routerLinkActiveOptions]="{ exact: !!item.exact }"
    [target]="item.type === 'bookmark' ? '_blank' : '_self'"
  >
    <svg-icon
      [key]="item.icon"
      [fontSize]="isSubMenuItem ? '8px' : '15px'"
      [ngClass]="{ 'ml-[10px]': isSubMenuItem }"
    ></svg-icon>
    <span class="flex-1" [ngClass]="{ 'pl-2': !isSubMenuItem, 'pl-[10px]': isSubMenuItem }">
      {{ item.label }}
    </span>
    <div *ngIf="item.subMenus" class="pl-2">
      <svg-icon key="arrow-down-simple" fontSize="10px"></svg-icon>
    </div>
    <div
      (click)="deleteBookmark($event, item.extraInfo)"
      *ngIf="item.type === 'bookmark' && !item.subMenus"
      class="pl-2 -mr-[3px] hidden group-hover:flex items-center"
    >
      <svg-icon
        *ngIf="deletingBookmarkId !== item.extraInfo"
        key="close-circle"
        fontSize="15px"
        color="#5E5E5E"
      ></svg-icon>
      <lla-loading-spinner
        *ngIf="deletingBookmarkId === item.extraInfo"
        class="w-[14px]"
        [size]="3"
      ></lla-loading-spinner>
    </div>
  </a>
</ng-template>
