import { ApplicationRoutes } from '../../../app-routes-names'

export const TiresRoutes = {
  list: 'list',
  summary: 'summary',
  lostTire: 'lost-tire',
  lostTiresList: 'lost-tire-list',
  offers: 'offers'
}

const { tires } = ApplicationRoutes

export const TiresPaths = {
  list: `/${tires}/${TiresRoutes.list}`,
  summary: `/${tires}/${TiresRoutes.summary}`,
  lostTire: `/${tires}/${TiresRoutes.lostTire}`,
  lostTiresList: `/${tires}/${TiresRoutes.lostTiresList}`,
  offers: `/${tires}/${TiresRoutes.offers}`
}
