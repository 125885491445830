export enum WorkCategoryType {
  NotSet = 'NotSet',
  CustomerService = 'CustomerService',
  Damage = 'Damage',
  Mechanical = 'Mechanical',
  Price = 'Price',
  Process = 'Process',
  Other = 'Other'
}

export const WorkCategoryTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not set',
  CustomerService: 'Customer service',
  Damage: 'Damage',
  Mechanical: 'Mechanical',
  Price: 'Price',
  Process: 'Process',
  Other: 'Other'
}
