import { Injectable } from '@angular/core'
import { FiltersStateModel } from '../stores/filters/filters.model'
import sign from 'jwt-encode'
import { environment } from '@lla-platform/core/core-util'
import { IUrlFilterItem } from '../interfaces/url-filters.interface'
import { AppVersion } from '../stores/filters/filters.constant'

@Injectable({
  providedIn: 'root'
})
export class SignUrlService {
  generateFilters(filters: Partial<FiltersStateModel>): IUrlFilterItem {
    return {
      filters: sign({ ...filters, appVersion: AppVersion }, environment.jwtSecret)
    }
  }
}
