import { ApplicationRoutes } from '../../../app-routes-names'

export const ShopPerformanceRoutes = {
  invoicesList: 'invoices-list',
  grossProfitInvoicesList: 'gross-invoices-list',
  gpPackageInvoicesList: 'package-gp-list',
  invoicesCustomerType: 'invoices-customer-type',
  invoicesDeferredType: 'invoices-deferred-type',
  invoicesGrossProfit: 'invoices-gross-profit',
  gpPackageLocation: 'package-gp-location',
  gpPackageCategory: 'package-gp-category',
  performancePackageGp: 'performance-package-gp',
  weeklyCarCount: 'weekly-car-count'
}

const { shopPerformance } = ApplicationRoutes

export const ShopPerformancePaths = {
  invoicesList: `/${shopPerformance}/${ShopPerformanceRoutes.invoicesList}`,
  grossProfitInvoicesList: `/${shopPerformance}/${ShopPerformanceRoutes.grossProfitInvoicesList}`,
  gpPackageInvoicesList: `/${shopPerformance}/${ShopPerformanceRoutes.gpPackageInvoicesList}`,
  invoicesCustomerType: `/${shopPerformance}/${ShopPerformanceRoutes.invoicesCustomerType}`,
  invoicesDeferredType: `/${shopPerformance}/${ShopPerformanceRoutes.invoicesDeferredType}`,
  invoicesGrossProfit: `/${shopPerformance}/${ShopPerformanceRoutes.invoicesGrossProfit}`,
  gpPackageLocation: `/${shopPerformance}/${ShopPerformanceRoutes.gpPackageLocation}`,
  gpPackageCategory: `/${shopPerformance}/${ShopPerformanceRoutes.gpPackageCategory}`,
  performancePackageGp: `/${shopPerformance}/${ShopPerformanceRoutes.performancePackageGp}`,
  weeklyCarCount: `/${shopPerformance}/${ShopPerformanceRoutes.weeklyCarCount}`
}
