import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { UsersService } from '../../users.service'
import { AddNewBookmark, DeleteBookmark, SetBookmarks } from './bookmarks.actions'
import { BookmarksStateModel } from './bookmarks.model'

@State<BookmarksStateModel>({
  name: 'bookmarks'
})
@Injectable()
export class BookmarksState {
  constructor(private usersService: UsersService) {}

  @Selector()
  static bookmarks(state: BookmarksStateModel) {
    return state.bookmarks ?? []
  }

  @Action(SetBookmarks)
  setBookmarks(ctx: StateContext<BookmarksStateModel>, { payload }: SetBookmarks) {
    ctx.patchState({
      bookmarks: payload
    })
  }

  @Action(AddNewBookmark)
  addNewBookmark(ctx: StateContext<BookmarksStateModel>, { payload }: AddNewBookmark) {
    return this.usersService.addNewBookmark(payload).pipe(
      tap((res) => {
        ctx.patchState({
          bookmarks: res?.items
        })
      })
    )
  }

  @Action(DeleteBookmark)
  deleteBookmark(ctx: StateContext<BookmarksStateModel>, { id }: DeleteBookmark) {
    return this.usersService.deleteBookmark(id).pipe(
      tap((res) => {
        ctx.patchState({
          bookmarks: res?.items
        })
      })
    )
  }
}
