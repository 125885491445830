import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { PaymentsStateModel } from './payments.model'
import { PaymentsService } from '../payments.service'
import { GetPaymentsList } from './payments.actions'

@State<PaymentsStateModel>({
  name: 'payments'
})
@Injectable()
export class PaymentsState {
  constructor(
    private paymentsService: PaymentsService,
    private tableFilterService: TableFilterService
  ) {}

  @Selector()
  static listResponse(state: PaymentsStateModel) {
    return state.listResponse
  }

  @Action(GetPaymentsList)
  getPaymentsList(ctx: StateContext<PaymentsStateModel>, { info }: GetPaymentsList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.paymentsService.getPaymentsList(this.tableFilterService.createRequestPayload(info)).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }
}
