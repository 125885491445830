import { ISelectItem } from '@lla-platform/core/core-ui'

export enum LostTireSoldType {
  NotSet = 'NotSet',
  Yes = 'Yes',
  No = 'No'
}

export const LostTireSoldTypeItems: ISelectItem[] = [
  {
    label: 'Not Set',
    value: LostTireSoldType.NotSet
  },
  {
    label: 'Yes',
    value: LostTireSoldType.Yes
  },
  {
    label: 'No',
    value: LostTireSoldType.No
  }
]
