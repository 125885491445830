import { ISurveyRequest } from '../../interfaces/survey.interface'

export class GetSurvey {
  static readonly type = '[survey] Get Survey'
  constructor(public surveyId: string, public locationId?: string) {}
}

export class SendSurveyResponse {
  static readonly type = '[survey] Send Survey Response'
  constructor(public surveyId: string, public payload: ISurveyRequest) {}
}

export class AdminGetSurveySummary {
  static readonly type = '[survey] Admin Get Survey Summary'
}
