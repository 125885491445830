import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ServiceCategoriesStateModel } from './service-categories.model'
import { ServiceCategoriesService } from '../service-categories.service'
import {
  AdminGetServiceCategoryThresolds,
  AdminUpdateServiceCategoryThresolds,
  GetServiceCategories,
  GetServiceCategoryThresolds
} from './service-categories.actions'

@State<ServiceCategoriesStateModel>({
  name: 'serviceCategories'
})
@Injectable()
export class ServiceCategoriesState {
  constructor(private apiService: ServiceCategoriesService) {}

  @Selector()
  static thresolds(state: ServiceCategoriesStateModel) {
    return state.thresolds
  }

  @Selector()
  static categories(state: ServiceCategoriesStateModel) {
    return state.categories
  }

  @Action(GetServiceCategories)
  getServiceCategories(ctx: StateContext<ServiceCategoriesStateModel>) {
    return this.apiService.getServiceCategories().pipe(
      tap((res) => {
        ctx.patchState({ categories: res.categories ?? [] })
      })
    )
  }

  @Action(GetServiceCategoryThresolds)
  getServiceCategoryThresolds(ctx: StateContext<ServiceCategoriesStateModel>) {
    return this.apiService.getThresolds().pipe(
      tap((res) => {
        ctx.patchState({
          thresolds: res?.items ?? []
        })
      })
    )
  }

  @Action(AdminGetServiceCategoryThresolds)
  adminGetServiceCategoryThresolds(ctx: StateContext<ServiceCategoriesStateModel>) {
    return this.apiService.adminGetThresolds().pipe(
      tap((res) => {
        ctx.patchState({
          thresolds: res?.items ?? []
        })
      })
    )
  }

  @Action(AdminUpdateServiceCategoryThresolds)
  adminUpdateServiceCategoryThresolds(
    ctx: StateContext<ServiceCategoriesStateModel>,
    { payload }: AdminUpdateServiceCategoryThresolds
  ) {
    return this.apiService.adminUpdateThresolds(payload).pipe(
      tap((res) => {
        ctx.patchState({
          thresolds: res?.items ?? []
        })
      })
    )
  }
}
