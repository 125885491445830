import { Injectable } from '@angular/core'
import { IGenericObject } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { IWeeklyCarCountsInfo } from '../../interfaces/weekly-car-counts.interface'
import { CarCountsService } from '../car-counts.service'
import { GetWeeklyCarCounts } from './weekly-car-counts.actions'
import { WeeklyCarCountsStateModel } from './weekly-car-counts.model'

@State<WeeklyCarCountsStateModel>({
  name: 'weeklyCarCounts'
})
@Injectable()
export class WeeklyCarCountsState {
  constructor(private carCountsService: CarCountsService) {}

  @Selector()
  static weeklyCarCounts(state: WeeklyCarCountsStateModel): IWeeklyCarCountsInfo | undefined {
    if (!state?.weeklyCarCounts) {
      return
    }
    const { weeklyCarCounts } = state
    const allPeriods = weeklyCarCounts.totals.map((el) => el.period)
    const totals: IGenericObject = Object.fromEntries(
      weeklyCarCounts.totals.map((el) => [
        el.period,
        {
          ...el,
          carCount: el.amount
        }
      ])
    )
    const locations: IGenericObject[] = []

    for (const location of weeklyCarCounts.locations) {
      locations.push({
        locationId: location.locationId,
        locationName: location.locationName,
        ...Object.fromEntries(location.periods.map((el) => [el.period, el]))
      })
    }

    const graphs: IGenericObject[] = [
      {
        stateLabel: 'Number of locations in "Missed"',
        state: 0,
        ...Object.fromEntries(weeklyCarCounts.graphs.map((el) => [el.period, el.missed]))
      },
      {
        stateLabel: 'Number of locations in "Tire 1"',
        state: 1,
        ...Object.fromEntries(weeklyCarCounts.graphs.map((el) => [el.period, el.tier1]))
      },
      {
        stateLabel: 'Number of locations in "Tire 2"',
        state: 2,
        ...Object.fromEntries(weeklyCarCounts.graphs.map((el) => [el.period, el.tier2]))
      },
      {
        stateLabel: 'Number of locations in "Tire 3"',
        state: 3,
        ...Object.fromEntries(weeklyCarCounts.graphs.map((el) => [el.period, el.tier3]))
      }
    ]

    return {
      graphs,
      locations,
      totals,
      allPeriods
    }
  }

  @Action(GetWeeklyCarCounts)
  getWeeklyCarCounts(ctx: StateContext<WeeklyCarCountsStateModel>, { payload }: GetWeeklyCarCounts) {
    return this.carCountsService.getWeeklyCarCounts(payload).pipe(
      tap((res) => {
        ctx.patchState({
          weeklyCarCounts: res
        })
      })
    )
  }
}
