import { ApplicationRoutes } from '../../../app-routes-names'

export const ReportsRoutes = {
  calls: 'calls',
  sales: 'sales'
}

const { reports } = ApplicationRoutes

export const ReportsPaths = {
  calls: `/${reports}/${ReportsRoutes.calls}`,
  sales: `/${reports}/${ReportsRoutes.sales}`
}
