import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  ICategoryResponse,
  IServiceCategoryThresoldsRequest,
  IServiceCategoryThresoldsResponse
} from '../interfaces/service-categories.interface'
import { SERVICE_CATEGORIES_API_URL } from './service-categories-api-routes'

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoriesService {
  constructor(private http: HttpService) {}

  getThresolds(): Observable<IServiceCategoryThresoldsResponse> {
    return this.http.get(SERVICE_CATEGORIES_API_URL.thresoldsBase)
  }

  adminGetThresolds(): Observable<IServiceCategoryThresoldsResponse> {
    return this.http.get(SERVICE_CATEGORIES_API_URL.adminThresoldsBase)
  }

  adminUpdateThresolds(
    payload: IServiceCategoryThresoldsRequest
  ): Observable<IServiceCategoryThresoldsResponse> {
    return this.http.post(SERVICE_CATEGORIES_API_URL.adminThresoldsBase, payload)
  }

  getServiceCategories(): Observable<ICategoryResponse> {
    return this.http.get(SERVICE_CATEGORIES_API_URL.getServiceCategories)
  }
}
