import { IAdvancedTableInfo } from '@lla-platform/core/core-data-access'
import { ILostTireRequest } from '../../interfaces/lost-tires.interface'
import { ITiresRequest } from '../../interfaces/tires.interface'
import { TireType } from '../../enums/tire-type.enum'

export class GetTiresSummary {
  static readonly type = '[tires] Get Tires Summary'
  constructor(public payload: ITiresRequest) {}
}

export class GetTiresList {
  static readonly type = '[tires] Get Tires List'
  constructor(public info: IAdvancedTableInfo) {}
}

export class UpdateTireItemIsTire {
  static readonly type = '[tires] Update Tire Item Is Tire'
  constructor(public itemId: string, public isTire: boolean, public tireType: TireType) {}
}

export class GetLostTireInfo {
  static readonly type = '[tires] Get Lost Tire Info'
}

export class AddLostTire {
  static readonly type = '[tires] Add Lost Tire'
  constructor(public payload: ILostTireRequest) {}
}

export class GetLostTiresList {
  static readonly type = '[tires] Get Lost Tires List'
  constructor(public info: IAdvancedTableInfo) {}
}
