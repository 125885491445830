export const DATE_CONSTANTS = {
  MIN_VALUE: '0001-01-01T00:00:00'
}

export const MONTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const MonthNumberToLabel: { [key: string]: string } = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December'
}

export const TimeSelectItems = [
  {
    label: '07:00 AM',
    value: 1,
    info: {
      hour: 7,
      min: 0
    }
  },
  {
    label: '07:30 AM',
    value: 2,
    info: {
      hour: 7,
      min: 30
    }
  },
  {
    label: '08:00 AM',
    value: 3,
    info: {
      hour: 8,
      min: 0
    }
  },
  {
    label: '08:30 AM',
    value: 4,
    info: {
      hour: 8,
      min: 30
    }
  },
  {
    label: '09:00 AM',
    value: 5,
    info: {
      hour: 9,
      min: 0
    }
  },
  {
    label: '09:30 AM',
    value: 6,
    info: {
      hour: 9,
      min: 30
    }
  },
  {
    label: '10:00 AM',
    value: 7,
    info: {
      hour: 10,
      min: 0
    }
  },
  {
    label: '10:30 AM',
    value: 8,
    info: {
      hour: 10,
      min: 30
    }
  },
  {
    label: '11:00 AM',
    value: 9,
    info: {
      hour: 11,
      min: 0
    }
  },
  {
    label: '11:30 AM',
    value: 10,
    info: {
      hour: 11,
      min: 30
    }
  },
  {
    label: '12:00 PM',
    value: 11,
    info: {
      hour: 12,
      min: 0
    }
  },
  {
    label: '12:30 PM',
    value: 12,
    info: {
      hour: 12,
      min: 30
    }
  },
  {
    label: '01:00 PM',
    value: 13,
    info: {
      hour: 13,
      min: 0
    }
  },
  {
    label: '01:30 PM',
    value: 14,
    info: {
      hour: 13,
      min: 30
    }
  },
  {
    label: '02:00 PM',
    value: 15,
    info: {
      hour: 14,
      min: 0
    }
  },
  {
    label: '02:30 PM',
    value: 16,
    info: {
      hour: 14,
      min: 30
    }
  },
  {
    label: '03:00 PM',
    value: 17,
    info: {
      hour: 15,
      min: 0
    }
  },
  {
    label: '03:30 PM',
    value: 18,
    info: {
      hour: 15,
      min: 30
    }
  },
  {
    label: '04:00 PM',
    value: 19,
    info: {
      hour: 16,
      min: 0
    }
  },
  {
    label: '04:30 PM',
    value: 20,
    info: {
      hour: 16,
      min: 30
    }
  },
  {
    label: '05:00 PM',
    value: 21,
    info: {
      hour: 17,
      min: 0
    }
  },
  {
    label: '05:30 PM',
    value: 22,
    info: {
      hour: 17,
      min: 30
    }
  },
  {
    label: '06:00 PM',
    value: 23,
    info: {
      hour: 18,
      min: 0
    }
  },
  {
    label: '06:30 PM',
    value: 24,
    info: {
      hour: 18,
      min: 30
    }
  },
  {
    label: '07:00 PM',
    value: 25,
    info: {
      hour: 19,
      min: 0
    }
  }
]
