export enum TagType {
  NeedResolution = 'NeedResolution',
  Rotation = 'Rotation',
  OilChange = 'OilChange'
}

export const TagTypeToLabel: { [key: string]: string } = {
  NeedResolution: 'Need resolution',
  Rotation: 'Rotation',
  OilChange: 'Oil change'
}
