import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { InventoriesService } from '../inventories.service'
import { InventoriesStateModel } from './inventories.model'
import { IInventorySummaryExtendedResponse } from '../../interfaces/inventories-summary.interface'
import { GetInventoriesList, GetInventoriesSummary, UpdateInventoryTire } from './inventories.actions'

@State<InventoriesStateModel>({
  name: 'inventories'
})
@Injectable()
export class InventoriesState {
  constructor(
    private inventoriesService: InventoriesService,
    private tableFilterService: TableFilterService
  ) {}

  @Selector()
  static summary(state: InventoriesStateModel): IInventorySummaryExtendedResponse | undefined {
    if (!state.summary) {
      return
    }
    return {
      inventories: state.summary.inventories.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,

        totalPartsTotal: el.totalParts?.total ?? 0,
        totalPartsQuantity: el.totalParts?.totalQuantity ?? 0,
        totalTiresAndAccessoriesTotal: el.totalTiresAndAccessories?.total ?? 0,
        totalTiresAndAccessoriesQuantity: el.totalTiresAndAccessories?.totalQuantity ?? 0,
        totalTiresTotal: el.totalTires?.total ?? 0,
        totalTiresQuantity: el.totalTires?.totalQuantity ?? 0,
        grandTotalsTotal: el.locationGrandTotals?.total ?? 0,
        grandTotalsQuantity: el.locationGrandTotals?.totalQuantity ?? 0,

        formattedTotalPartsTotal: el.totalParts?.formattedTotal ?? '',
        formattedTotalPartsQuantity: el.totalParts?.formattedTotalQuantity ?? '',
        formattedTotalTiresAndAccessoriesTotal: el.totalTiresAndAccessories?.formattedTotal ?? '',
        formattedTotalTiresAndAccessoriesQuantity:
          el.totalTiresAndAccessories?.formattedTotalQuantity ?? '',
        formattedTotalTiresTotal: el.totalTires?.formattedTotal ?? '',
        formattedTotalTiresQuantity: el.totalTires?.formattedTotalQuantity ?? '',
        formattedGrandTotalsTotal: el.locationGrandTotals?.formattedTotal ?? '',
        formattedGrandTotalsQuantity: el.locationGrandTotals?.formattedTotalQuantity ?? ''
      })),
      grandTotal: {
        totalPartsTotal: state.summary.grandTotalParts?.total ?? 0,
        totalPartsQuantity: state.summary.grandTotalParts?.totalQuantity ?? 0,
        totalTiresAndAccessoriesTotal: state.summary.grandTotalTiresAndAccessories?.total ?? 0,
        totalTiresAndAccessoriesQuantity:
          state.summary.grandTotalTiresAndAccessories?.totalQuantity ?? 0,
        totalTiresTotal: state.summary.grandTotalTires?.total ?? 0,
        totalTiresQuantity: state.summary.grandTotalTires?.totalQuantity ?? 0,
        grandTotalsTotal: state.summary.grandTotals?.total ?? 0,
        grandTotalsQuantity: state.summary.grandTotals?.totalQuantity ?? 0,

        formattedTotalPartsTotal: state.summary.grandTotalParts?.formattedTotal ?? '',
        formattedTotalPartsQuantity: state.summary.grandTotalParts?.formattedTotalQuantity ?? '',
        formattedTotalTiresAndAccessoriesTotal:
          state.summary.grandTotalTiresAndAccessories?.formattedTotal ?? '',
        formattedTotalTiresAndAccessoriesQuantity:
          state.summary.grandTotalTiresAndAccessories?.formattedTotalQuantity ?? '',
        formattedTotalTiresTotal: state.summary.grandTotalTires?.formattedTotal ?? '',
        formattedTotalTiresQuantity: state.summary.grandTotalTires?.formattedTotalQuantity ?? '',
        formattedGrandTotalsTotal: state.summary.grandTotals?.formattedTotal ?? '',
        formattedGrandTotalsQuantity: state.summary.grandTotals?.formattedTotalQuantity ?? ''
      }
    }
  }

  @Selector()
  static listResponse(state: InventoriesStateModel) {
    return state.listResponse
  }

  @Action(GetInventoriesSummary)
  getInventoriesSummary(ctx: StateContext<InventoriesStateModel>) {
    return this.inventoriesService.getInventoriesSummary().pipe(
      tap((res) => {
        ctx.patchState({
          summary: res
        })
      })
    )
  }

  @Action(GetInventoriesList)
  getInventoriesList(ctx: StateContext<InventoriesStateModel>, { info }: GetInventoriesList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.inventoriesService
      .getInventoriesList(this.tableFilterService.createRequestPayload(info))
      .pipe(
        tap((res) => {
          ctx.patchState({
            listResponse: res
          })
        })
      )
  }

  @Action(UpdateInventoryTire)
  updateInventoryTire(
    ctx: StateContext<InventoriesStateModel>,
    { inventoryId, isTire }: UpdateInventoryTire
  ) {
    return this.inventoriesService.updateTire(inventoryId, isTire)
  }
}
