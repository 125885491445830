import { ITimePeriodRequest } from '@lla-platform/core/core-data-access'

export class GetCallsReport {
  static readonly type = '[reports] Get Calls Report'
  constructor(public payload: ITimePeriodRequest) {}
}

export class GetSalesReport {
  static readonly type = '[reports] Get Sales Report'
}
