import { IAdvancedTableInfo } from '../../interfaces/advanced-table.interface'
import { FilterStateKeys, FiltersStateModel } from './filters.model'

export class SetFilters {
  static readonly type = '[Filters] Set Filters'
  constructor(public filters: Partial<FiltersStateModel>) {}
}

export class SetAdvancedTableFilters {
  static readonly type = '[Filters] Set Advanced Table Filters'
  constructor(public key: FilterStateKeys, public value: Partial<IAdvancedTableInfo>) {}
}

export class SetFiltersFromUrlQueryParams {
  static readonly type = '[Filters] Set Filters From Url QueryParams'
  constructor(public filtersToken: string) {}
}

export class SetUrlQueryParamsFromState {
  static readonly type = '[Filters] Set Url Query Params From State'
}
