export enum TierType {
  Missed = 'Missed',
  Tier1 = 'Tier1',
  Tier2 = 'Tier2',
  Tier3 = 'Tier3'
}

export const TierTypeToLabel: { [key: string]: string } = {
  Missed: 'Missed',
  Tier1: 'Tier 1',
  Tier2: 'Tier 2',
  Tier3: 'Tier 3'
}
