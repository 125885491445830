import { environment } from '@lla-platform/core/core-util'

export const RECEIVABLE_API_URL = {
  // Receivables
  base: (receivableId: string) => `${environment.apiUrl}/receivables/${receivableId}`,
  detailedList: `${environment.apiUrl}/receivables/filters`,
  exportReceivablesList: `${environment.apiUrl}/receivables/filters/export`,
  receivablesSummaryByShop: `${environment.apiUrl}/receivables/summary/location`,
  exportReceivablesSummaryByShop: `${environment.apiUrl}/receivables/summary/location/export`,
  receivablesSummaryByAccount: `${environment.apiUrl}/receivables/summary/accountName`,
  exportReceivablesSummaryByAccount: `${environment.apiUrl}/receivables/summary/accountName/export`,
  arInvoicesSummary: `${environment.apiUrl}/receivables/summary/ar`,
  exportArInvoicesSummary: `${environment.apiUrl}/receivables/summary/ar/export`,

  // Invoices
  arInvoicesList: `${environment.apiUrl}/invoices/filters`,
  exportArInvoicesList: `${environment.apiUrl}/invoices/filters/export`,
  getInvoice: (invoiceId: string) => `${environment.apiUrl}/invoices/${invoiceId}`,

  // Invoices GP
  getGrossProfit: `${environment.apiUrl}/invoices/invoice-gp`,
  exportGrossProfit: `${environment.apiUrl}/invoices/invoice-gp/export`,
  grossProfitList: `${environment.apiUrl}/invoices/filters/invoice-gp`,
  exportGrossProfitList: `${environment.apiUrl}/invoices/filters/export/invoice-gp`,

  // Invoices GP Packages
  getGpPackage: `${environment.apiUrl}/invoicepackages/gp`,
  exportGpPackage: `${environment.apiUrl}/invoicepackages/gp/export`,
  gpPackageList: `${environment.apiUrl}/invoicepackages/filters/gp`,
  exportGpPackageList: `${environment.apiUrl}/invoicepackages/filters/export/gp`,
  getPackageUnperformed: `${environment.apiUrl}/invoicepackages/unperformed`,
  exportPackageUnperformed: `${environment.apiUrl}/invoicepackages/unperformed/export`,

  // AR Payment
  arPaymentsSummary: `${environment.apiUrl}/receivables/summary/ar-payments`,
  exportArPaymentsSummary: `${environment.apiUrl}/receivables/summary/ar-payments/export`
}
