import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IShopLocationItem } from '../interfaces/shops.interface'
import { SHOPS_API_URL } from './shops-api-routes'

@Injectable({
  providedIn: 'root'
})
export class ShopsService {
  constructor(private http: HttpService) {}

  getAllShops(): Observable<IShopLocationItem[]> {
    return this.http.get(SHOPS_API_URL.allShops)
  }
}
