import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, throwError } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpOptions = {
    withCredentials: true,
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache'
    })
  }

  constructor(private http: HttpClient) {}

  post<T>(url: string, payload: any, options: RequestOptions = {}): Observable<any> {
    return this.http
      .post<T>(url, payload, { ...this.httpOptions, ...options })
      .pipe(catchError((error, caught) => this.handleError(error, caught)))
  }

  get<T>(url: string, options: RequestOptions = {}): Observable<any> {
    return this.http
      .get<T>(url, { ...this.httpOptions, ...options })
      .pipe(catchError((error, caught) => this.handleError(error, caught)))
  }

  delete<T>(url: string, payload?: any, options: RequestOptions = {}): Observable<any> {
    return this.http
      .delete<T>(url, { ...this.httpOptions, ...options, body: payload })
      .pipe(catchError((error, caught) => this.handleError(error, caught)))
  }

  put<T>(url: string, payload?: any, options: RequestOptions = {}): Observable<any> {
    return this.http
      .put<T>(url, payload, { ...this.httpOptions, ...options })
      .pipe(catchError((error, caught) => this.handleError(error, caught)))
  }

  patch<T>(url: string, payload?: any, options: RequestOptions = {}): Observable<any> {
    return this.http
      .patch<T>(url, payload, { ...this.httpOptions, ...options })
      .pipe(catchError((error, caught) => this.handleError(error, caught)))
  }

  handleError(error: HttpErrorResponse, caught: any) {
    return throwError(() => error)
  }
}

export interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  params?:
    | HttpParams
    | {
        [param: string]: string | string[]
      }
  reportProgress?: boolean
  responseType?: 'json' | any
  withCredentials?: boolean
}
