<div class="modal-container relative pb-5.5">
  <!-- Close Btn -->
  <div class="absolute z-50 cursor-pointer top-1 right-3">
    <svg-icon color="#374151" key="close" fontSize="10px" (click)="dialogRef.close()"></svg-icon>
  </div>

  <lla-invoice-info
    [forModal]="true"
    [showCloseButton]="false"
    [invoiceId]="data.invoiceId"
  ></lla-invoice-info>
</div>
