import { environment } from '@lla-platform/core/core-util'

export const TIRES_API_URL = {
  tiresSummary: `${environment.apiUrl}/inventories/tires-summary`,
  exportTiresSummary: `${environment.apiUrl}/inventories/tires-summary/export`,
  tiresList: `${environment.apiUrl}/inventories/filters/tires`,
  exportTiresList: `${environment.apiUrl}/inventories/filters/tires/export`,
  updateTire: (itemId: string) => `${environment.apiUrl}/inventories/update-tire/spl/${itemId}`,
  lostTire: `${environment.apiUrl}/tires/form`,
  lostTiresList: `${environment.apiUrl}/tires/filters/lost-tires`,
  exportLostTiresList: `${environment.apiUrl}/tires/filters/lost-tires/export`
}
