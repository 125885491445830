export enum PromotionChannelType {
  Other = 'Other',
  Groupon = 'Groupon',
  GoogleYelp = 'GoogleYelp',
  PostcardMailer = 'PostcardMailer',
  TextEmail = 'TextEmail',
  NationalTireRebate = 'NationalTireRebate',
  EventDonation = 'EventDonation',
  FinancingPromo = 'FinancingPromo',
  ShopSpecificPromo = 'ShopSpecificPromo',
  SocialMedia = 'SocialMedia',
  StandardPromo = 'StandardPromo',
  RadioTv = 'RadioTv'
}
