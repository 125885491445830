import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { MainLayoutComponent } from './main-layout/main-layout.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { CoreFeatureModule } from '@lla-platform/core/core-feature'

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [CommonModule, RouterModule, SvgIconsModule, MatMenuModule, MatIconModule, CoreFeatureModule],
  exports: [MainLayoutComponent]
})
export class MainLayoutModule {}
