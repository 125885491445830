<div class="h-full flex flex-col" *ngIf="currentUser$ | async as currentUser">
  <div class="print:hidden" [ngSwitch]="currentUser.role">
    <lla-main-header
      [mainMenuRoutes]="userMainRoutes"
      [profileMenuRoutes]="userProfileRoutes"
      (showLargeMenuChanged)="onShowLargeMenuChange($event)"
    ></lla-main-header>
  </div>

  <div class="flex-1 flex w-full overflow-auto">
    <lla-main-menu *ngIf="showLargeMenu" [mainMenuRoutes]="userMainRoutes"></lla-main-menu>
    <div class="flex-1 flex flex-col mx-auto overflow-auto beautyScroll">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
