<div class="w-full flex h-screen overflow-hidden">
  <div class="flex-1 bg-primary-dark h-full hidden lg:block">
    <div class="logo-section h-full relative flex flex-col justify-end">
      <img class="absolute left-8 top-8 w-[360px]" src="assets/images/icons/new-big-logo.svg" />

      <div class="text-white">
        <div class="text-4xl leading-5xl mb-4">Left Lane</div>

        <div class="text-2xl leading-2.5xl">
          Providing you with real-time market benchmarks, trends and insights
        </div>
      </div>
    </div>
  </div>
  <div class="flex-1 h-full overflow-auto">
    <div class="content-container mx-auto h-full py-5 px-3">
      <div class="mb-3 flex lg:hidden justify-center items-center">
        <img class="h-8" src="assets/images/icons/new-big-logo.svg" />
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
