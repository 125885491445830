import { Injectable } from '@angular/core'
import {
  IStringTimePeriod,
  ITimePeriodRequest,
  PeriodType,
  PeriodTypeButtonsInfo
} from '@lla-platform/core/core-data-access'
import { IRangeDateValue } from '@lla-platform/core/core-ui'
import moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class DateService {
  convertToDateString(date: string | Date | undefined) {
    if (!date) {
      return
    }
    return moment(date).utc().format().split('T')[0]
  }

  getFullDateWithoutTimeZone(info: { date: Date; hour?: number; min?: number }) {
    const date = new Date(info.date)
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T${
      info.hour ? (info.hour > 9 ? info.hour : `0${info.hour}`) : '00'
    }:${info.min ? (info.min > 9 ? info.min : `0${info.min}`) : '00'}:00.000Z`
  }

  periodTypeToDate(periodType: PeriodType): Required<IRangeDateValue> {
    let dateInfo = PeriodTypeButtonsInfo.find((el) => el.value === periodType)?.extraInfo
    if (!dateInfo) {
      dateInfo = PeriodTypeButtonsInfo.find((el) => el.value === PeriodType.WeekToDate)?.extraInfo
    }

    return { from: dateInfo.from.format(), to: dateInfo.to.format() }
  }

  generateStringTimePeriod(value: Partial<ITimePeriodRequest>): IStringTimePeriod {
    return {
      from: value?.from ? this.convertToDateString(new Date(value.from)) : undefined,
      to: value?.to ? this.convertToDateString(new Date(value.to)) : undefined
    }
  }

  periodTypeToDateString(periodType: PeriodType) {
    const dateInfo = this.periodTypeToDate(periodType)
    return this.generateStringTimePeriod(dateInfo)
  }
}
