import { ITrackerRequest } from '../../interfaces/trackers.interface'

export class GetTrackerLocations {
  static readonly type = '[tracker] Get Tracker Locations'
}

export class GetTrackersData {
  static readonly type = '[tracker] Get Trackers Data'
  constructor(public payload: ITrackerRequest) {}
}

export class ResetTrackers {
  static readonly type = '[tracker] Reset Trackers'
}
