<!-- Just small media: This div used to reseve the space for header even with absolute elements -->
<div class="h-5.5 lg:hidden"></div>

<!-- Main content -->
<div class="border-b border-gray-100">
  <div
    class="fixed top-0 z-[250] lg:relative w-full bg-white py-1 mx-auto px-3 lg:px-5 flex items-center justify-center lg:block border-b border-gray-100 lg:border-b-0"
  >
    <div
      class="lg:hidden absolute left-4 cursor-pointer top-2"
      (click)="showMenu = !showMenu"
      [ngClass]="{ 'top-[10px]': showMenu, 'top-2': !showMenu }"
    >
      <svg-icon
        [key]="showMenu ? 'close-light' : 'menu'"
        color="#111827"
        [fontSize]="showMenu ? '12px' : '20px'"
      ></svg-icon>
    </div>

    <div class="h-4 hidden lg:flex items-center">
      <div
        (click)="showLargeMenu = !showLargeMenu; showLargeMenuChanged.emit(showLargeMenu)"
        class="h-full group cursor-pointer"
        *ngIf="showLargeMenu"
      >
        <img class="group-hover:hidden h-full" src="assets/images/icons/new-logo.svg" alt="Logo" />
        <div class="hidden group-hover:flex h-full items-center text-primary-dark">
          <svg-icon key="sidebar-middle" fontSize="18px"></svg-icon>
        </div>
      </div>
      <div
        (click)="showLargeMenu = !showLargeMenu; showLargeMenuChanged.emit(showLargeMenu)"
        *ngIf="!showLargeMenu"
        class="h-full flex items-center text-primary-dark cursor-pointer"
      >
        <svg-icon key="sidebar-left" fontSize="18px"></svg-icon>
      </div>
    </div>

    <img class="lg:hidden h-4 cursor-pointer" src="assets/images/icons/new-logo.svg" alt="Logo" />

    <div class="absolute top-[10px] right-4">
      <button
        (click)="menuTrigger.openMenu(); showMenu = false"
        class="relative flex items-center justify-end pb-2 menu-buttons"
      >
        <div
          class="pr-3.5 hidden lg:block text-lg leading-xl text-txt-secondary"
          *ngIf="currentUser$ | async; let currentUser"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </div>
        <svg-icon key="settings" color="#5E5E5E" fontSize="18px"></svg-icon>
      </button>
      <div [matMenuTriggerFor]="profileMenu" #menuTrigger="matMenuTrigger"></div>
      <mat-menu
        #profileMenu="matMenu"
        [hasBackdrop]="true"
        backdropClass="profile-menu-backdrop"
        class="profile-mat-menu"
      >
        <ng-template matMenuContent>
          <div
            class="block lg:hidden text-lg leading-xl text-txt font-medium border-b border-gray-100 p-2"
            *ngIf="currentUser$ | async; let currentUser"
          >
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
          <div class="flex flex-col pb-1 pt-0 md:pt-1 menu-buttons">
            <a
              *ngFor="let item of profileMenuRoutes"
              class="profile-menu-item"
              [routerLink]="item.path"
              routerLinkActive="text-primary"
              (click)="hideCollapsableMenu()"
            >
              <svg-icon class="mr-2" [key]="item.icon" color="#111827" fontSize="14px"></svg-icon>
              <span>{{ item.label }}</span>
            </a>

            <a class="profile-menu-item !pb-1 border-t border-gray-100" (click)="logout()">
              <svg-icon class="mr-2" key="exit" color="#111827" fontSize="14px"></svg-icon>
              <span>Log Out</span>
            </a>
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
</div>

<!-- Just small media: Closable menu  -->
<div
  class="closable-menu w-full fixed bg-white max-h-[65%] beautyScroll overflow-y-auto"
  *ngIf="showMenu"
>
  <ng-container *ngFor="let item of mainMenuRoutes; let i = index">
    <ng-container
      *ngTemplateOutlet="expandableMenuItem; context: { item: item, index: i }"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="bookmarks && bookmarks.length > 0">
    <ng-container
      *ngTemplateOutlet="
        menuItem;
        context: {
          item: {
            path: '',
            icon: 'bookmark',
            label: 'Bookmarks',
            subMenus: []
          }
        }
      "
    ></ng-container>
    <ng-container *ngFor="let item of bookmarks">
      <ng-container *ngTemplateOutlet="bookmarkMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- Just small media: Backdrop -->
<div
  *ngIf="showMenu"
  (click)="showMenu = false"
  class="lg:hidden fixed top-0 left-0 right-0 bottom-0 bg-txt/50 z-[200]"
></div>

<!-- Expandable Menu Item Template -->
<ng-template #expandableMenuItem let-index="index" let-item="item">
  <div>
    <ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
  </div>
  <ng-container>
    <ng-container *ngFor="let sumMenu of item.subMenus">
      <ng-container *ngTemplateOutlet="menuItem; context: { item: sumMenu }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Item Template -->
<ng-template #menuItem let-item="item">
  <a
    *ngIf="!item.hidden"
    class="closable-menu-item"
    [routerLink]="item.path"
    [queryParams]="item.queryParams"
    (click)="hideCollapsableMenu()"
  >
    <svg-icon class="mr-2" [key]="item.icon" color="#111827" fontSize="14px"></svg-icon>
    <span>{{ item.label }}</span>
    <div *ngIf="item.subMenus" class="ml-2 flex-1 h-[1px] bg-gray-200"></div>
  </a>
</ng-template>

<!-- Bookmark Item Template -->
<ng-template #bookmarkMenuItem let-item="item">
  <a
    class="closable-menu-item"
    [routerLink]="item.path"
    [queryParams]="item.queryParams"
    target="_blank"
    (click)="hideCollapsableMenu()"
  >
    <svg-icon [key]="item.icon"></svg-icon>
    <span class="pl-2 flex-1">{{ item.label }}</span>
    <div (click)="deleteBookmark($event, item.extraInfo)" class="pl-2 -mr-[3px] flex items-center">
      <svg-icon
        *ngIf="deletingBookmarkId !== item.extraInfo"
        key="close-circle"
        fontSize="15px"
        color="#C3C3C3"
      ></svg-icon>
      <lla-loading-spinner
        *ngIf="deletingBookmarkId === item.extraInfo"
        class="w-[14px]"
        [size]="3"
      ></lla-loading-spinner>
    </div>
  </a>
</ng-template>
