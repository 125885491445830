import { Injectable } from '@angular/core'
import {
  HttpService,
  IAdvancedTableInfo,
  IExportFileResponse,
  IPaginationFilteredRequest,
  TableFilterService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IPaymentsListResponse } from '../interfaces/payments-list.interface'
import { PAYMENTS_API_URL } from './payments-api-routes'

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(private http: HttpService, private tableFilterService: TableFilterService) {}

  getPaymentsList(payload: IPaginationFilteredRequest): Observable<IPaymentsListResponse> {
    return this.http.post(PAYMENTS_API_URL.paymentsList, payload)
  }

  exportPaymentsList(payload: IAdvancedTableInfo): Observable<IExportFileResponse> {
    return this.http.post(
      PAYMENTS_API_URL.exportPaymentsList,
      this.tableFilterService.createRequestPayload(payload)
    )
  }
}
