import { IAdminShopTiersUpdate } from '../../interfaces/admin-shop-tires.interface'
import { IAdminUpdatePermissionsRequest } from '../../interfaces/admin-user-permissions.interface'

export class AdminGetShopTires {
  static readonly type = '[admin] Admin Get Shop Tires'
}

export class AdminUpdateShopTires {
  static readonly type = '[admin] Admin Update Shop Tires'
  constructor(public payload: IAdminShopTiersUpdate) {}
}

export class AdminGetAllUsers {
  static readonly type = '[admin] Admin Get All Users'
}

export class AdminGetUserPermissions {
  static readonly type = '[admin] Admin Get User Permissions'
  constructor(public userId: string) {}
}

export class AdminUpdateUserPermissions {
  static readonly type = '[admin] Admin Update User Permissions'
  constructor(public userId: string, public payload: IAdminUpdatePermissionsRequest) {}
}

export class AdminGetAllPermissions {
  static readonly type = '[admin] Admin Get All Permissions'
}
