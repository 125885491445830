import { ApplicationRoutes } from '../../../app-routes-names'

export const PaymentsRoutes = {
  paymentsList: 'payments-list'
}

const { payments } = ApplicationRoutes

export const PaymentsPaths = {
  paymentsList: `/${payments}/${PaymentsRoutes.paymentsList}`
}
