import { IPageData } from '../../interfaces/page.interface'

export class GetWhoToCallPage {
  static readonly type = '[page] Get Who To Call Page'
}

export class UpdateWhoToCallPage {
  static readonly type = '[page] Update Who To Call Page'
  constructor(public payload: IPageData) {}
}
