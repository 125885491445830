import { Injectable } from '@angular/core'
import { isEmpty, isObject } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  convertEmptyToNull(payload: any) {
    const objectKeys = Object.keys(payload)
    for (const key of objectKeys) {
      const value = payload[key]
      if (isObject(value) && isEmpty(value)) {
        payload[key] = null
      }
    }
    return payload
  }

  generateRandomColor() {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  generateRandomString() {
    return (Math.random() + 1).toString(36).substring(2)
  }

  blobToBase64(file: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.readAsDataURL(file)
    })
  }
}
