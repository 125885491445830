import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IUserRegisterRequest } from './interfaces/user-register.interface'
import { IUserLoginRequest } from './interfaces/user-login.interface'
import { IUser } from './interfaces/user.interface'
import { USERS_API_URL } from './users-api-routes'
import {
  IRequestPasswordReset,
  IResetPassword,
  IUpdatePasswordRequest
} from './interfaces/password.interface'
import { IUpdateProfileRequest } from './interfaces/update-profile.interface'
import {
  IUserPreferencesRequest,
  IUserPreferencesResponse
} from './interfaces/user-preferences.interface'
import { IAddBookmarkRequest, IBookmarkResponse } from './interfaces/bookmarks.interface'
import { IUserDeleteRequest } from './interfaces/user-delete.interface'
import { IUserUpdateRequest } from './interfaces/user-update.interface'

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpService) {}

  login(payload: IUserLoginRequest) {
    return this.http.post(USERS_API_URL.login, payload)
  }

  logout() {
    return this.http.post(USERS_API_URL.logout, {})
  }

  getFullUserInformation(): Observable<IUser> {
    return this.http.get<IUser>(USERS_API_URL.me)
  }

  register(payload: IUserRegisterRequest) {
    return this.http.post(USERS_API_URL.register, payload)
  }

  publicRegister(payload: IUserRegisterRequest) {
    return this.http.post(USERS_API_URL.publicRegister, payload)
  }

  deleteUser(payload: IUserDeleteRequest) {
    return this.http.put(USERS_API_URL.deleteUser, payload)
  }

  updateUser(payload: IUserUpdateRequest) {
    return this.http.put(USERS_API_URL.updateUser, payload)
  }

  requestPasswordReset(payload: IRequestPasswordReset) {
    return this.http.post(USERS_API_URL.requestPasswordReset, payload)
  }

  resetPassword(payload: IResetPassword) {
    return this.http.post(USERS_API_URL.resetPassword, payload)
  }

  updateProfile(payload: IUpdateProfileRequest) {
    return this.http.put(USERS_API_URL.updateProfile, payload)
  }

  updatePassword(payload: IUpdatePasswordRequest) {
    return this.http.put(USERS_API_URL.updatePassword, payload)
  }

  getUserPreferences(): Observable<IUserPreferencesResponse> {
    return this.http.get(USERS_API_URL.preferences)
  }

  updateUserPreferences(payload: IUserPreferencesRequest) {
    return this.http.put(USERS_API_URL.preferences, payload)
  }

  addNewBookmark(payload: IAddBookmarkRequest): Observable<IBookmarkResponse> {
    return this.http.post(USERS_API_URL.bookmarks(), payload)
  }

  deleteBookmark(id: string): Observable<IBookmarkResponse> {
    return this.http.delete(USERS_API_URL.bookmarks(id))
  }

  getAdminUserPreferences(userId: string): Observable<IUserPreferencesResponse> {
    return this.http.get(USERS_API_URL.adminUserPreferences(userId))
  }

  updateAdminUserPreferences(userId: string, payload: IUserPreferencesRequest) {
    return this.http.put(USERS_API_URL.adminUserPreferences(userId), payload)
  }
}
