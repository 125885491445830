import { IPromotionEmailRequest } from '../../interfaces/promotion-users.interface'
import { IPromotionItem } from '../../interfaces/promotions.interface'

export class GetOnePromotion {
  static readonly type = '[promotions] Get One Promotion'
  constructor(public promotionId: string) {}
}

export class GetAllPromotions {
  static readonly type = '[promotions] Get All Promotions'
}

export class AdminGetAllPromotions {
  static readonly type = '[promotions] Admin Get All Promotions'
}

export class AddPromotion {
  static readonly type = '[promotions] Add Promotion'
  constructor(public payload: IPromotionItem) {}
}

export class UpdatePromotion {
  static readonly type = '[promotions] Update Promotion'
  constructor(public promotionId: string, public payload: IPromotionItem) {}
}

export class DeletePromotion {
  static readonly type = '[promotions] delete Promotion'
  constructor(public promotionId: string) {}
}

export class AdminGetPromotionsChannels {
  static readonly type = '[promotions] Admin Get Promotions Channels'
}

export class UploadPromotionsAttachments {
  static readonly type = '[promotions] Upload Promotions Attachments'
  constructor(public promotionId: string, public files?: File[]) {}
}

export class DeletePromotionAttachment {
  static readonly type = '[promotions] Delete Promotion Attachment'
  constructor(public promotionId: string, public fileId: string) {}
}

export class GetPromotionUsers {
  static readonly type = '[promotions] Get Promotion Users'
}

export class SendPromotionEmail {
  static readonly type = '[promotions] Send Promotion Email'
  constructor(public payload: IPromotionEmailRequest) {}
}
