import { ApplicationRoutes } from '../../../app-routes-names'

export const TargetsRoutes = {
  objectives: 'objectives',
  locations: 'locations',
  regions: 'regions'
}

const { targets } = ApplicationRoutes

export const TargetsPaths = {
  objectives: `/${targets}/${TargetsRoutes.objectives}`,
  locations: `/${targets}/${TargetsRoutes.locations}`,
  regions: `/${targets}/${TargetsRoutes.regions}`
}
