import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { SurveyStateModel } from './survey.model'
import { SurveyService } from '../survey.service'
import { AdminGetSurveySummary, GetSurvey, SendSurveyResponse } from './survey.actions'

@State<SurveyStateModel>({
  name: 'survey'
})
@Injectable()
export class SurveyState {
  constructor(private surveyService: SurveyService) {}

  @Selector()
  static survey(state: SurveyStateModel) {
    return state.survey
  }

  @Selector()
  static adminSurveyItems(state: SurveyStateModel) {
    return state.adminSurveyItems
  }

  @Action(GetSurvey)
  getSurvey(ctx: StateContext<SurveyStateModel>, { surveyId, locationId }: GetSurvey) {
    return this.surveyService.getSurvey(surveyId, locationId).pipe(
      tap((res) => {
        ctx.patchState({
          survey: res
        })
      })
    )
  }

  @Action(SendSurveyResponse)
  sendSurveyResponse(ctx: StateContext<SurveyStateModel>, { surveyId, payload }: SendSurveyResponse) {
    return this.surveyService.sendSurveyResponse(surveyId, payload)
  }

  @Action(AdminGetSurveySummary)
  adminGetSurveySummary(ctx: StateContext<SurveyStateModel>) {
    return this.surveyService.adminGetSurveySummary().pipe(
      tap((res) => {
        ctx.patchState({
          adminSurveyItems: res?.items ?? []
        })
      })
    )
  }
}
