import { IReplyReviewRequest } from '../../interfaces/reply-review.interface'
import { IReplySugestionRequest } from '../../interfaces/reply-sugestion.interface'

export class GetReviews {
  static readonly type = '[reviews] Get Reviews'
}

export class ReplySugestion {
  static readonly type = '[reviews] Reply Sugestion'
  constructor(public payload: Partial<IReplySugestionRequest>) {}
}

export class ReplyReview {
  static readonly type = '[reviews] Reply Review'
  constructor(public payload: IReplyReviewRequest) {}
}
