export enum StarRating {
  STAR_RATING_UNSPECIFIED = 'STAR_RATING_UNSPECIFIED',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE'
}

export const StarRatingToLabel: { [key: string]: string } = {
  STAR_RATING_UNSPECIFIED: 'Not specified.',
  ONE: 'One star out of a maximum of five.',
  TWO: 'Two stars out of a maximum of five.',
  THREE: 'Three stars out of a maximum of five.',
  FOUR: 'Four stars out of a maximum of five.',
  FIVE: 'The maximum star rating.'
}

export const StarRatingToNumber: { [key: string]: number } = {
  STAR_RATING_UNSPECIFIED: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5
}
