export enum FullReceivableType {
  All = 'All',
  NotRated = 'NotRated',
  LowRisk = 'LowRisk',
  HighRisk = 'HighRisk'
}

export enum ReceivableType {
  NotRated = 'NotRated',
  LowRisk = 'LowRisk',
  HighRisk = 'HighRisk'
}
