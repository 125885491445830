import { Injectable, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { mergeMap, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { IUser } from '../../interfaces/user.interface'
import { UsersService } from '../../users.service'
import { SetBookmarks } from '../bookmarks/bookmarks.actions'
import {
  GetCurrentUser,
  Login,
  Register,
  Logout,
  RequestPasswordReset,
  ResetPassword,
  UpdateProfile,
  UpdatePassword,
  SetReturnUrl,
  NavigateToReturnUrl,
  GetUserPreferences,
  UpdateUserPreferences,
  DeleteUser,
  UpdateUser,
  GetAdminUserPreferences,
  UpdateAdminUserPreferences,
  PublicRegister
} from './user-account.actions'
import { UserAccountStateModel } from './user-account.model'
import { SetGroupShops } from '@lla-platform/shops/shops-data-access'
import { IUserPreferencesResponse } from '../../interfaces/user-preferences.interface'

@State<UserAccountStateModel>({
  name: 'userAccount'
})
@Injectable()
export class UserAccountState {
  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private store: Store
  ) {}

  @Selector()
  static isAuthenticated(state: UserAccountStateModel) {
    return state.isAuthenticated
  }

  @Selector()
  static currentUser(state: UserAccountStateModel) {
    return state.user
  }

  @Selector()
  static userPreferences(state: UserAccountStateModel): IUserPreferencesResponse {
    return (
      state.userPreferences ?? {
        preferences: [],
        availablePreferences: []
      }
    )
  }

  @Selector()
  static permissions(state: UserAccountStateModel) {
    return state.user?.permissions ?? []
  }

  @Selector()
  static shouldReload(state: UserAccountStateModel) {
    return state.shouldReload
  }

  @Action(Login)
  login(ctx: StateContext<UserAccountStateModel>, { payload }: Login) {
    return this.usersService.login(payload).pipe(mergeMap(() => ctx.dispatch(new GetCurrentUser())))
  }

  @Action(Logout)
  logout(ctx: StateContext<UserAccountStateModel>) {
    if (ctx.getState().isAuthenticated) {
      return this.usersService.logout().pipe(
        tap(() => {
          ctx.patchState({ user: undefined, isAuthenticated: false, shouldReload: true })
        })
      )
    }
    return of()
  }

  @Action(GetCurrentUser)
  getCurrentUser(ctx: StateContext<UserAccountStateModel>) {
    return this.usersService.getFullUserInformation().pipe(
      tap((res: IUser) => {
        ctx.patchState({ user: res, isAuthenticated: true })
        this.store.dispatch(new SetBookmarks(res.bookmarks))
        this.store.dispatch(
          new SetGroupShops({
            locations: res.userLocations ?? []
          })
        )
      })
    )
  }

  @Action(Register)
  register(ctx: StateContext<UserAccountStateModel>, { payload }: Register) {
    return this.usersService.register(payload)
  }

  @Action(PublicRegister)
  publicRegister(ctx: StateContext<UserAccountStateModel>, { payload }: PublicRegister) {
    return this.usersService.publicRegister(payload)
  }

  @Action(DeleteUser)
  deleteUser(ctx: StateContext<UserAccountStateModel>, { payload }: DeleteUser) {
    return this.usersService.deleteUser(payload)
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateUser) {
    return this.usersService.updateUser(payload)
  }

  @Action(RequestPasswordReset)
  requestPasswordReset(ctx: StateContext<UserAccountStateModel>, { payload }: RequestPasswordReset) {
    return this.usersService.requestPasswordReset(payload)
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<UserAccountStateModel>, { payload }: ResetPassword) {
    return this.usersService
      .resetPassword(payload)
      .pipe(mergeMap(() => ctx.dispatch(new GetCurrentUser())))
  }

  @Action(UpdateProfile)
  updateProfile(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateProfile) {
    return this.usersService
      .updateProfile(payload)
      .pipe(mergeMap(() => ctx.dispatch(new GetCurrentUser())))
  }

  @Action(UpdatePassword)
  updatePassword(ctx: StateContext<UserAccountStateModel>, { payload }: UpdatePassword) {
    return this.usersService.updatePassword(payload)
  }

  @Action(GetUserPreferences)
  getUserPreferences(ctx: StateContext<UserAccountStateModel>) {
    return this.usersService.getUserPreferences().pipe(
      tap((res) => {
        ctx.patchState({ userPreferences: res })
      })
    )
  }

  @Action(UpdateUserPreferences, { cancelUncompleted: true })
  updateUserPreferences(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateUserPreferences) {
    return this.usersService.updateUserPreferences({ preferences: payload })
  }

  @Action(SetReturnUrl)
  setReturnUrl(ctx: StateContext<UserAccountStateModel>) {
    const { returnUrl } = ctx.getState()
    if (returnUrl && returnUrl !== '/') {
      return
    }
    const fromQuery: string = this.activatedRoute.snapshot.queryParams['returnUrl']
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        /\/[^\\/].*/.test(fromQuery) ||
        fromQuery === '/'
      )
    ) {
      // This is an extra check to prevent open redirects.
      this.toastrService.error(
        'Invalid return url. The return url needs to have the same origin as the current page.'
      )
      ctx.patchState({
        returnUrl: '/'
      })
      return
    }

    ctx.patchState({
      returnUrl: fromQuery || '/'
    })
  }

  @Action(NavigateToReturnUrl)
  async navigateToReturnUrl(ctx: StateContext<UserAccountStateModel>) {
    await this.ngZone.run(() =>
      this.router.navigateByUrl(ctx.getState()?.returnUrl ?? '/', {
        replaceUrl: true
      })
    )
    ctx.patchState({
      returnUrl: undefined
    })
  }

  @Action(GetAdminUserPreferences)
  getAdminUserPreferences(
    ctx: StateContext<UserAccountStateModel>,
    { userId }: GetAdminUserPreferences
  ) {
    return this.usersService.getAdminUserPreferences(userId).pipe(
      tap((res) => {
        ctx.patchState({ userPreferences: res })
      })
    )
  }

  @Action(UpdateAdminUserPreferences, { cancelUncompleted: true })
  updateAdminUserPreferences(
    ctx: StateContext<UserAccountStateModel>,
    { userId, payload }: UpdateAdminUserPreferences
  ) {
    return this.usersService.updateAdminUserPreferences(userId, { preferences: payload })
  }
}
