import { TableFilterType } from '@lla-platform/core/core-data-access'
import { ISelectItem } from '@lla-platform/core/core-ui'

export const TableOperatorToLabel = {
  LessThan: 'Less than',
  LessThanOrEqual: 'Less than or equal',
  Equals: 'Equals',
  NotEquals: 'Not equal',
  GreaterThanOrEqual: 'Greater than or equal',
  GreaterThan: 'Greater than',
  Contains: 'Contains',
  StartsWith: 'Starts with',
  EndsWith: 'Ends with',
  Period: 'Period'
}

export const TableOperatorToSentence = {
  LessThan: 'is less than',
  LessThanOrEqual: 'is less than or equal to',
  Equals: 'is equal to',
  NotEquals: 'is not equal to',
  GreaterThanOrEqual: 'is greater than or equal to',
  GreaterThan: 'is greater than',
  Contains: 'contains',
  StartsWith: 'starts with',
  EndsWith: 'ends with',
  Period: 'in'
}

export const StringOperators: ISelectItem[] = [
  {
    label: TableOperatorToLabel.Contains,
    value: 'Contains'
  },
  {
    label: TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: TableOperatorToLabel.StartsWith,
    value: 'StartsWith'
  },
  {
    label: TableOperatorToLabel.EndsWith,
    value: 'EndsWith'
  }
]

export const NumberOperators: ISelectItem[] = [
  {
    label: TableOperatorToLabel.LessThan,
    value: 'LessThan'
  },
  {
    label: TableOperatorToLabel.LessThanOrEqual,
    value: 'LessThanOrEqual'
  },
  {
    label: TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: TableOperatorToLabel.GreaterThanOrEqual,
    value: 'GreaterThanOrEqual'
  },
  {
    label: TableOperatorToLabel.GreaterThan,
    value: 'GreaterThan'
  }
]

export const DateOperators: ISelectItem[] = [
  {
    label: TableOperatorToLabel.LessThan,
    value: 'LessThan'
  },
  {
    label: TableOperatorToLabel.LessThanOrEqual,
    value: 'LessThanOrEqual'
  },
  {
    label: TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: TableOperatorToLabel.GreaterThanOrEqual,
    value: 'GreaterThanOrEqual'
  },
  {
    label: TableOperatorToLabel.GreaterThan,
    value: 'GreaterThan'
  }
]

export const BooleanStringOperators: ISelectItem[] = [
  {
    label: TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  }
]

export const NoFilterTypes: TableFilterType[] = ['guid']

export const CustomFilterTypes: TableFilterType[] = [
  'enum',
  'enum-radio',
  'custom-date',
  'location-list',
  'category-list'
]

export const TotalRowUniqueId = 'advanced_table_total_row_unique_id'

export const BooleanEnumItems: ISelectItem[] = [
  {
    label: 'True',
    value: true
  },
  {
    label: 'False',
    value: false
  }
]
