import { environment } from '@lla-platform/core/core-util'

export const INVENTORIES_API_URL = {
  summary: `${environment.apiUrl}/inventories`,
  exportSummary: `${environment.apiUrl}/inventories/summary/export`,
  list: `${environment.apiUrl}/inventories/filters`,
  exportList: `${environment.apiUrl}/inventories/filters/export`,
  updateTire: (inventoryId: string) =>
    `${environment.apiUrl}/inventories/update-tire/inventory/${inventoryId}`
}
