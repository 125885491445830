export enum CommunicationSource {
  NotSet = 'NotSet',
  CustomerServiceCalls = 'CustomerServiceCalls',
  Resolution = 'Resolution',
  DeferredWork = 'DeferredWork',
  GoogleReview = 'GoogleReview',
  Kukui = 'Kukui',
  Autovitals = 'Autovitals',
  LeftVoicemail = 'LeftVoicemail',
  StoreLevel = 'StoreLevel'
}

export const CommunicationSourceToLabel: { [key: string]: string } = {
  NotSet: 'Not set',
  CustomerServiceCalls: 'Customer service calls',
  Resolution: 'Resolution',
  DeferredWork: 'Deferred work',
  GoogleReview: 'Google review',
  Kukui: 'Kukui',
  Autovitals: 'Autovitals',
  LeftVoicemail: 'Left voicemail',
  StoreLevel: 'Store level'
}
