import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { ReviewsStateModel } from './reviews.model'
import { ReviewsService } from '../reviews.service'
import { GetReviews, ReplyReview, ReplySugestion } from './reviews.actions'
import { tap } from 'rxjs'
import { cloneDeep, sortBy } from 'lodash'
import { IReviewShopInfo } from '../../interfaces/reviews.interface'

@State<ReviewsStateModel>({
  name: 'reviews'
})
@Injectable()
export class ReviewsState {
  constructor(private reviewsService: ReviewsService) {}

  @Selector()
  static shopReviews(state: ReviewsStateModel) {
    return state.shopReviews
  }

  @Selector()
  static allShops(state: ReviewsStateModel): IReviewShopInfo[] {
    return (
      state.shopReviews
        ?.map((el) => ({
          locationName: el.locationName,
          locationId: el.locationId
        }))
        .flat() ?? []
    )
  }

  @Selector()
  static replyMessage(state: ReviewsStateModel) {
    return state.replyMessage
  }

  @Action(GetReviews)
  getReviews(ctx: StateContext<ReviewsStateModel>) {
    return this.reviewsService.getReviews().pipe(
      tap((res) => {
        ctx.patchState({
          shopReviews: res ? sortBy(res.locations, (x) => x.locationName) : undefined
        })
      })
    )
  }

  @Action(ReplySugestion)
  replySugestion(ctx: StateContext<ReviewsStateModel>, { payload }: ReplySugestion) {
    return this.reviewsService.replySugestion(payload).pipe(
      tap((res) => {
        ctx.patchState({
          replyMessage: res?.message
        })
      })
    )
  }

  @Action(ReplyReview)
  replyReview(ctx: StateContext<ReviewsStateModel>, { payload }: ReplyReview) {
    let shopReviews = cloneDeep(ctx.getState()?.shopReviews ?? [])
    return this.reviewsService.replyReview(payload).pipe(
      tap(() => {
        const index = shopReviews.findIndex((el) => el.locationId === payload.locationId)
        const shop = shopReviews[index]
        shop.reviews = shop.reviews.filter((el) => el.reviewId !== payload.reviewId)
        if (shop.reviews.length < 1) {
          shopReviews = shopReviews.filter((el) => el.locationId !== payload.locationId)
        } else {
          shopReviews[index] = shop
        }

        ctx.patchState({
          shopReviews
        })
      })
    )
  }
}
