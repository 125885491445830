import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CoreUiModule } from '@lla-platform/core/core-ui'
import { MainHeaderComponent } from './components/main-header/main-header.component'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component'
import { AdvancedTableComponent } from './components/advanced-table/advanced-table.component'
import { AdvancedTableFilterComponent } from './components/advanced-table-filter/advanced-table-filter.component'
import { MatTableModule } from '@angular/material/table'
import { NgxPaginationModule } from 'ngx-pagination'
import { MatSortModule } from '@angular/material/sort'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MainMenuComponent } from './components/main-menu/main-menu.component'
import { TableHeaderComponent } from './components/table-header/table-header.component'
import { AddBookmarkDialogComponent } from './components/add-bookmark-dialog/add-bookmark-dialog.component'
import { InvoiceInfoComponent } from './components/invoice-info/invoice-info.component'
import { LineChartComponent } from './components/line-chart/line-chart.component'
import { BarChartComponent } from './components/bar-chart/bar-chart.component'
import { ShopsSelectorComponent } from './components/shops-selector/shops-selector.component'
import { InvoiceInfoDialogComponent } from './components/invoice-info-dialog/invoice-info-dialog.component'
import { ClipboardModule } from 'ngx-clipboard'
import { TimePeriodFilterComponent } from './components/time-period-filter/time-period-filter.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { InputRangeDateFilterComponent } from './components/input-range-date-filter/input-range-date-filter.component'
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreUiModule,
    RouterModule,
    SvgIconsModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    NgxPaginationModule,
    MatSortModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule
  ],
  declarations: [
    AddBookmarkDialogComponent,
    MainHeaderComponent,
    ProfileLayoutComponent,
    AdvancedTableComponent,
    AdvancedTableFilterComponent,
    MainMenuComponent,
    TableHeaderComponent,
    InvoiceInfoComponent,
    LineChartComponent,
    BarChartComponent,
    ShopsSelectorComponent,
    InvoiceInfoDialogComponent,
    TimePeriodFilterComponent,
    InputRangeDateFilterComponent
  ],
  exports: [
    MainHeaderComponent,
    ProfileLayoutComponent,
    AdvancedTableComponent,
    AdvancedTableFilterComponent,
    MainMenuComponent,
    TableHeaderComponent,
    InvoiceInfoComponent,
    LineChartComponent,
    BarChartComponent,
    ShopsSelectorComponent,
    InvoiceInfoDialogComponent,
    TimePeriodFilterComponent,
    InputRangeDateFilterComponent
  ],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }]
})
export class CoreFeatureModule {}
