import { ApplicationRoutes } from '../../../app-routes-names'

export const SamplesRoutes = {
  sankeyChart: 'sankey-chart',
  salesChart: 'sales-chart',
  compChart: 'comp-chart',
  map: 'map',
  shopMap: 'location-map',
  mapClustering: 'map-clustering',
  mapMarkers: 'map-markers'
}

const { samples } = ApplicationRoutes

export const SamplesPaths = {
  sankeyChart: `/${samples}/${SamplesRoutes.sankeyChart}`,
  salesChart: `/${samples}/${SamplesRoutes.salesChart}`,
  compChart: `/${samples}/${SamplesRoutes.compChart}`,
  map: `/${samples}/${SamplesRoutes.map}`,
  shopMap: `/${samples}/${SamplesRoutes.shopMap}`,
  mapClustering: `/${samples}/${SamplesRoutes.mapClustering}`,
  mapMarkers: `/${samples}/${SamplesRoutes.mapMarkers}`
}
