export enum ContactType {
  NotSet = 'NotSet',
  New = 'New',
  Friends = 'Friends',
  Advocates = 'Advocates'
}

export const ContactTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  New: 'New (first invoice)',
  Friends: 'Friends (2-4 invoices)',
  Advocates: 'Advocates (over 4 invoices)'
}
