import { ApplicationRoutes } from '../../../app-routes-names'

export const InventoriesRoutes = {
  summary: 'summary',
  list: 'list'
}

const { inventories } = ApplicationRoutes

export const InventoriesPaths = {
  summary: `/${inventories}/${InventoriesRoutes.summary}`,
  list: `/${inventories}/${InventoriesRoutes.list}`
}
