import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IPageData } from '../interfaces/page.interface'
import { PAGE_API_URL } from './page-api-routes'

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private http: HttpService) {}

  getPageData(type: string): Observable<IPageData> {
    return this.http.get(PAGE_API_URL.pageBaseUrl, {
      params: {
        type
      }
    })
  }

  updatePageData(payload: IPageData) {
    return this.http.post(PAGE_API_URL.pageBaseUrl, payload)
  }
}
