import { ApplicationRoutes } from '../../app-routes-names'

export const ProfileRoutes = {
  editProfile: 'edit-profile',
  editPassword: 'edit-password',
  editPreferences: 'edit-preferences'
}

const { profile } = ApplicationRoutes

export const ProfilePaths = {
  editProfile: `/${profile}/${ProfileRoutes.editProfile}`,
  editPassword: `/${profile}/${ProfileRoutes.editPassword}`,
  editPreferences: `/${profile}/${ProfileRoutes.editPreferences}`
}
