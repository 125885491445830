import { ISelectItem } from '@lla-platform/core/core-ui'

export enum MissedReasonType {
  NotInStock = 'NotInStock',
  PriceTooHigh = 'PriceTooHigh',
  PriceTooLow = 'PriceTooLow',
  InventoryRequest = 'InventoryRequest',
  Other = 'Other '
}

export const MissedReasonTypeItems: ISelectItem[] = [
  {
    label: 'Not In Stock',
    value: MissedReasonType.NotInStock
  },
  {
    label: 'Price Too High',
    value: MissedReasonType.PriceTooHigh
  },
  {
    label: 'Price Too Low',
    value: MissedReasonType.PriceTooLow
  },
  {
    label: 'Inventory Request',
    value: MissedReasonType.InventoryRequest
  },
  {
    label: 'Other',
    value: MissedReasonType.Other
  }
]
