import { ISelectItem } from '@lla-platform/core/core-ui'

export enum ChapterCodeType {
  NotSet = 'NotSet',
  Service = 'Service',
  Inspection = 'Inspection',
  Concern = 'Concern',
  GeneralCredits = 'GeneralCredits',
  DeferredService = 'DeferredService',
  DeferredConcern = 'DeferredConcern',
  DeferredInspection = 'DeferredInspection'
}

export const ChapterCodeTypeItems: ISelectItem[] = [
  {
    label: 'Not Set',
    value: ChapterCodeType.NotSet
  },
  {
    label: 'Service',
    value: ChapterCodeType.Service
  },
  {
    label: 'Inspection',
    value: ChapterCodeType.Inspection
  },
  {
    label: 'Concern',
    value: ChapterCodeType.Concern
  },
  {
    label: 'General Credits',
    value: ChapterCodeType.GeneralCredits
  },
  {
    label: 'Deferred Service',
    value: ChapterCodeType.DeferredService
  },
  {
    label: 'Deferred Concern',
    value: ChapterCodeType.DeferredConcern
  },
  {
    label: 'Deferred Inspection',
    value: ChapterCodeType.DeferredInspection
  }
]
