export enum TargetType {
  None = 'None',
  Sales = 'Sales',
  CarCount = 'CarCount',
  AverageTicket = 'AverageTicket',
  Alignments = 'Alignments',
  Tires = 'Tires',
  Fluids = 'Fluids',
  Brakes = 'Brakes',
  GrossProfit = 'GrossProfit'
}

export const TargetTypeToLabel: { [key: string]: string } = {
  None: 'None',
  Sales: 'Sales',
  CarCount: 'Car Count',
  AverageTicket: 'Average Ticket',
  Alignments: 'Alignments',
  Tires: 'Tires',
  Fluids: 'Fluids',
  Brakes: 'Brakes',
  GrossProfit: 'Gross Profit'
}

export const TargetTypeSign: { [key: string]: string } = {
  None: '',
  Sales: '$',
  CarCount: '',
  AverageTicket: '$',
  Alignments: '',
  Tires: '',
  Fluids: '$',
  Brakes: '$',
  GrossProfit: '%'
}
