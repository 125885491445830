import { AbstractControl, ValidatorFn } from '@angular/forms'
import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneNumberUtil = PhoneNumberUtil.getInstance()

export function PhoneNumberValidator(regionCode: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value || control.value === '') {
      return null
    }
    let validNumber = false
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode)
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber)
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return validNumber ? null : { [`${regionCode}-phone`]: true }
  }
}
