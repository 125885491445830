import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReceivableService } from '../receivable.service'
import {
  GetReceivableDetailedList,
  GetAccountReceivables,
  GetShopReceivables,
  UpdateReceivable
} from './receivable.actions'
import { ReceivableStateModel } from './receivable.model'

@State<ReceivableStateModel>({
  name: 'receivable'
})
@Injectable()
export class ReceivableState {
  constructor(
    private receivableService: ReceivableService,
    private tableFilterService: TableFilterService
  ) {}

  @Selector()
  static receivableDetailedResponse(state: ReceivableStateModel) {
    return state.receivableDetailedResponse
  }

  @Selector()
  static shopReceivableResponse(state: ReceivableStateModel) {
    return {
      locationReceivables: state.shopReceivableResponse?.locationReceivables ?? [],
      grandTotal: state.shopReceivableResponse?.grandTotal
    }
  }

  @Selector()
  static accountReceivableResponse(state: ReceivableStateModel) {
    return {
      locationReceivables: state.accountReceivableResponse?.locationReceivables ?? [],
      grandTotal: state.accountReceivableResponse?.grandTotal
    }
  }

  @Action(GetReceivableDetailedList)
  getReceivableDetailedList(
    ctx: StateContext<ReceivableStateModel>,
    { info }: GetReceivableDetailedList
  ) {
    ctx.patchState({
      receivableDetailedResponse: undefined
    })
    return this.receivableService
      .getDetailedList(this.tableFilterService.createRequestPayload(info))
      .pipe(
        tap((res) => {
          ctx.patchState({
            receivableDetailedResponse: res
          })
        })
      )
  }

  @Action(GetShopReceivables)
  getShopReceivables(ctx: StateContext<ReceivableStateModel>, { payload }: GetShopReceivables) {
    return this.receivableService.getReceivablesSummaryByShop(payload).pipe(
      tap((res) => {
        ctx.patchState({
          shopReceivableResponse: res
        })
      })
    )
  }

  @Action(GetAccountReceivables)
  getAccountReceivables(ctx: StateContext<ReceivableStateModel>, { payload }: GetAccountReceivables) {
    return this.receivableService.getReceivablesSummaryByAccount(payload).pipe(
      tap((res) => {
        ctx.patchState({
          accountReceivableResponse: res
        })
      })
    )
  }

  @Action(UpdateReceivable)
  updateReceivable(
    ctx: StateContext<ReceivableStateModel>,
    { receivableId, payload }: UpdateReceivable
  ) {
    return this.receivableService.updateReceivable(receivableId, payload)
  }
}
