import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-invoice-info-dialog',
  templateUrl: './invoice-info-dialog.component.html',
  styleUrls: ['./invoice-info-dialog.component.scss']
})
export class InvoiceInfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InvoiceInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { invoiceId: string; shopName: string }
  ) {}
}
