import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { catchError, map, Observable, of, switchMap, take } from 'rxjs'
import {
  GetServiceCategories,
  ICategoryItem,
  ServiceCategoriesState
} from 'service-categories-data-access'

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoriesGuard {
  @Select(ServiceCategoriesState.categories) categories$: Observable<ICategoryItem[]>

  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    return this.categories$.pipe(
      switchMap((categories) => {
        return categories
          ? of(categories)
          : this.store.dispatch(new GetServiceCategories()).pipe(map((state) => state.categories))
      }),
      catchError(() => {
        return of(null)
      }),
      take(1),
      map(() => true)
    )
  }
}
