import { Injectable } from '@angular/core'
import {
  HttpService,
  IAdvancedTableInfo,
  IExportFileResponse,
  IPaginationFilteredRequest,
  TableFilterService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IInventorySummaryResponse } from '../interfaces/inventories-summary.interface'
import { INVENTORIES_API_URL } from './inventories-api-routes'
import { IInventoriesListResponse } from '../interfaces/inventories-list.interface'

@Injectable({
  providedIn: 'root'
})
export class InventoriesService {
  constructor(private http: HttpService, private tableFilterService: TableFilterService) {}

  getInventoriesSummary(): Observable<IInventorySummaryResponse> {
    return this.http.get(INVENTORIES_API_URL.summary)
  }

  exportInventoriesSummary(): Observable<IExportFileResponse> {
    return this.http.get(INVENTORIES_API_URL.exportSummary)
  }

  getInventoriesList(payload: IPaginationFilteredRequest): Observable<IInventoriesListResponse> {
    return this.http.post(INVENTORIES_API_URL.list, payload)
  }

  exportInventoriesList(payload: IAdvancedTableInfo): Observable<IExportFileResponse> {
    return this.http.post(
      INVENTORIES_API_URL.exportList,
      this.tableFilterService.createRequestPayload(payload)
    )
  }

  updateTire(inventoryId: string, isTire: boolean) {
    return this.http.post(INVENTORIES_API_URL.updateTire(inventoryId), {
      isTire
    })
  }
}
