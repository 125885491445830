import { IServiceCategoryThresoldsRequest } from '../../interfaces/service-categories.interface'

export class GetServiceCategories {
  static readonly type = '[ServiceCategories] Get Service Categories'
}

export class GetServiceCategoryThresolds {
  static readonly type = '[ServiceCategories] Get Service Category Thresolds'
}

export class AdminGetServiceCategoryThresolds {
  static readonly type = '[ServiceCategories] Admin Get Service Category Thresolds'
}

export class AdminUpdateServiceCategoryThresolds {
  static readonly type = '[ServiceCategories] Admin Update Service Category Thresolds'
  constructor(public payload: IServiceCategoryThresoldsRequest) {}
}
