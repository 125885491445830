import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { PromotionsStateModel } from './promotions.model'
import { PromotionsService } from '../promotions.service'
import {
  AddPromotion,
  AdminGetAllPromotions,
  AdminGetPromotionsChannels,
  DeletePromotion,
  DeletePromotionAttachment,
  GetAllPromotions,
  GetOnePromotion,
  GetPromotionUsers,
  SendPromotionEmail,
  UpdatePromotion,
  UploadPromotionsAttachments
} from './promotions.actions'

@State<PromotionsStateModel>({
  name: 'promotions'
})
@Injectable()
export class PromotionsState {
  constructor(private promotionsService: PromotionsService) {}

  @Selector()
  static promotion(state: PromotionsStateModel) {
    return state.promotion
  }

  @Selector()
  static allPromotions(state: PromotionsStateModel) {
    return state.allPromotions
  }

  @Selector()
  static adminAllPromotions(state: PromotionsStateModel) {
    return state.adminAllPromotions
  }

  @Selector()
  static promotionChannels(state: PromotionsStateModel) {
    return state.promotionChannels
  }

  @Selector()
  static promotionUsers(state: PromotionsStateModel) {
    return state.promotionUsers
  }

  @Action(GetOnePromotion)
  getOnePromotion(ctx: StateContext<PromotionsStateModel>, { promotionId }: GetOnePromotion) {
    return this.promotionsService.getPromotion(promotionId).pipe(
      tap((res) => {
        ctx.patchState({
          promotion: res
        })
      })
    )
  }

  @Action(GetAllPromotions)
  getAllPromotions(ctx: StateContext<PromotionsStateModel>) {
    return this.promotionsService.getAllPromotions().pipe(
      tap((res) => {
        ctx.patchState({
          allPromotions: res?.locationsPromotions ?? []
        })
      })
    )
  }

  @Action(AdminGetAllPromotions)
  adminGetAllPromotions(ctx: StateContext<PromotionsStateModel>) {
    return this.promotionsService.adminGetAllPromotions().pipe(
      tap((res) => {
        ctx.patchState({
          adminAllPromotions: res?.items ?? []
        })
      })
    )
  }

  @Action(AddPromotion)
  addPromotion(ctx: StateContext<PromotionsStateModel>, { payload }: AddPromotion) {
    return this.promotionsService.addPromotion(payload).pipe(
      tap((res) => {
        ctx.patchState({
          promotion: res?.promotion
        })
      })
    )
  }

  @Action(UpdatePromotion)
  updatePromotion(ctx: StateContext<PromotionsStateModel>, { promotionId, payload }: UpdatePromotion) {
    return this.promotionsService.updatePromotion(promotionId, payload)
  }

  @Action(DeletePromotion)
  deletePromotion(ctx: StateContext<PromotionsStateModel>, { promotionId }: DeletePromotion) {
    return this.promotionsService.deletePromotion(promotionId)
  }

  @Action(AdminGetPromotionsChannels)
  adminGetPromotionsChannels(ctx: StateContext<PromotionsStateModel>) {
    const channels = ctx.getState()?.promotionChannels
    if (channels && channels.length > 0) {
      return
    }
    return this.promotionsService.getPromotionsChannels().pipe(
      tap((res) => {
        ctx.patchState({
          promotionChannels: res?.items ?? []
        })
      })
    )
  }

  @Action(UploadPromotionsAttachments)
  uploadPromotionsAttachments(
    ctx: StateContext<PromotionsStateModel>,
    { promotionId, files }: UploadPromotionsAttachments
  ) {
    return this.promotionsService.uploadPromotionsAttachments(promotionId, files)
  }

  @Action(DeletePromotionAttachment)
  deletePromotionAttachment(
    ctx: StateContext<PromotionsStateModel>,
    { promotionId, fileId }: DeletePromotionAttachment
  ) {
    return this.promotionsService.deletePromotionAttachment(promotionId, fileId)
  }

  @Action(GetPromotionUsers)
  getPromotionUsers(ctx: StateContext<PromotionsStateModel>) {
    return this.promotionsService.getPromotionUsers().pipe(
      tap((res) => {
        ctx.patchState({
          promotionUsers: res?.items ?? []
        })
      })
    )
  }

  @Action(SendPromotionEmail)
  sendPromotionEmail(ctx: StateContext<PromotionsStateModel>, { payload }: SendPromotionEmail) {
    return this.promotionsService.sendPromotionEmail(payload)
  }
}
