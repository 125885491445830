import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { CoreFeatureModule } from '@lla-platform/core/core-feature'
import { PublicLayoutComponent } from './public-layout/public-layout.component'

@NgModule({
  declarations: [PublicLayoutComponent],
  imports: [CommonModule, RouterModule, SvgIconsModule, CoreFeatureModule],
  exports: [PublicLayoutComponent]
})
export class PublicLayoutModule {}
