import { environment } from '@lla-platform/core/core-util'

export const USERS_API_URL = {
  // Users
  base: (userId: string) => `${environment.apiUrl}/users/${userId}`,
  login: `${environment.apiUrl}/users/login`,
  logout: `${environment.apiUrl}/users/logout`,
  me: `${environment.apiUrl}/users/me`,
  requestPasswordReset: `${environment.apiUrl}/users/requestpasswordreset`,
  resetPassword: `${environment.apiUrl}/users/resetpassword`,
  updateProfile: `${environment.apiUrl}/users`,
  updatePassword: `${environment.apiUrl}/users/password`,
  preferences: `${environment.apiUrl}/users/preferences`,
  publicRegister: `${environment.apiUrl}/users/register`,

  // Bookmarks
  bookmarks: (id?: string) => `${environment.apiUrl}/bookmarks${id ? '/' + id : ''}`,

  // Admin users
  register: `${environment.apiUrl}/admin/users/register`,
  deleteUser: `${environment.apiUrl}/admin/users/delete`,
  updateUser: `${environment.apiUrl}/admin/users/update`,
  adminUserPreferences: (userId: string) => `${environment.apiUrl}/admin/users/preferences/${userId}`
}
