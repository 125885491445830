import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { ShopPerformancePaths } from '../../features/user-panel/shop-performance/shop-performance-route-names'
import { Store } from '@ngxs/store'
import { PeriodType, SetFilters } from '@lla-platform/core/core-data-access'
import moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class PerformancePackageGpGuard {
  constructor(private router: Router, private store: Store) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const locationId = route.queryParams['locationId']
    const date = route.queryParams['date']

    if (locationId && date) {
      this.store.dispatch(
        new SetFilters({
          packageUnperformedSummaryFilter: {
            locationId,
            periodType: PeriodType.Custom,
            from: moment(date).utcOffset(0, true).format(),
            to: moment(date).utcOffset(0, true).format()
          }
        })
      )
    }
    this.router.navigateByUrl(ShopPerformancePaths.performancePackageGp)

    return true
  }
}
