import { ApplicationRoutes } from '../../../app-routes-names'

export const CommunicationsRoutes = {
  list: 'list'
}

const { communications } = ApplicationRoutes

export const CommunicationsPaths = {
  list: `/${communications}/${CommunicationsRoutes.list}`
}
