import { IAdvancedTableInfo } from '@lla-platform/core/core-data-access'
import { IUpdateReceivableRequest } from '../../interfaces/receivable.interface'
import { IReceivablesSummaryByShopRequest } from '../../interfaces/shops-receivable.interface'

export class GetReceivableDetailedList {
  static readonly type = '[receivable] Get Receivable Detailed List'
  constructor(public info: IAdvancedTableInfo) {}
}

export class GetShopReceivables {
  static readonly type = '[receivable] Get Shop Receivables'
  constructor(public payload: IReceivablesSummaryByShopRequest) {}
}

export class UpdateReceivable {
  static readonly type = '[receivable] Update Receivable'
  constructor(public receivableId: string, public payload: IUpdateReceivableRequest) {}
}

export class GetAccountReceivables {
  static readonly type = '[receivable] Get Account Receivables'
  constructor(public payload: IReceivablesSummaryByShopRequest) {}
}
